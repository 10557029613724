import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AutoChargeDialog from './AutoChargeDialog';
import CancelChargeDialog from './CancelChargeDialog';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';


import { connect } from 'react-redux';
import {
  getAutoChargeUser,
  clearFn,
  loadingFn
} from '../../../../actions/appActions';

class AutoCharge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      amount: '10',
      enabled: false,
      status: '',
      open_dialog: false,
      open_dialog_cancel: false,
      startDate: this.todayStart(),
      value_radio: 'paypal',
      errors: {}
    };
  }

  async UNSAFE_componentWillMount() {
    this.props.clearFn();
    this.props.loadingFn(true);
    await this.props.getAutoChargeUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user_autocharge.length !== 0) {
      this.setState({
        amount: parseFloat(
          nextProps.user_autocharge[0].value !== null
            ? nextProps.user_autocharge[0].value
            : '10'
        ).toFixed(0),
        enabled: nextProps.user_autocharge[0].value !== null ? true : false,
        status: nextProps.user_autocharge[0].charge_status
      });
    } else {
      this.setState({
        enabled: false,
        status: ''
      });
    }
  }

  todayStart() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return new Date(now);
  }

  handleStartDateChange = (date) => {
    const now = this.todayStart();
    if (
      date.getMonth() >= now.getMonth() &&
      date.getFullYear() >= now.getFullYear()
    ) {
      this.setState({ startDate: date });
    } else {
      this.setState({ startDate: now });
    }
  };

  handleSave = async (e) => {
    e.preventDefault();
    const { enabled } = this.state;
    this.setState({
      open_dialog_cancel: enabled ? true : false,
      open_dialog: enabled ? false: true
    });
  };

  handleChange = (e) => {
    e.persist();
    this.setState({ amount: e.target.value });
  };
  handleDialogClose = async () => {
    this.setState({ open_dialog: false });
    this.props.loadingFn(true);
    await this.props.getAutoChargeUser();
  };
  handleDialogCloseCancel = async () => {
    this.setState({ open_dialog_cancel: false });
    this.props.loadingFn(true);
    await this.props.getAutoChargeUser();
  };

  render() {
    const { loading, amount, startDate, enabled, value_radio, open_dialog,  open_dialog_cancel, status } = this.state;

    return (

      <Card>
        <AutoChargeDialog
          open={open_dialog}
          handleClose={this.handleDialogClose}
          amount={amount}
          startDate={startDate}
        />
         <CancelChargeDialog
          open={open_dialog_cancel}
          handleClose={this.handleDialogCloseCancel}
          amount={amount}
          startDate={startDate}
        />
        <form>
          <CardHeader
            subheader={!enabled ? 'Information' : amount + ' USD /Month'}
            title="Auto Charge"
          />
          <Divider />
          <CardContent>
            <Typography gutterBottom>
             Your <b>amount</b> for <b>monthly</b> AUTO CHARGE Account Balance.
            </Typography>
            <Typography gutterBottom>
             Status: <b>{status}</b>
            </Typography>
            <FormControl
              variant="outlined"
              style={{ padding: '15px', width: '100%' }}>
              <InputLabel
                id="period"
                style={{ paddingTop: '21px', paddingLeft: '15px' }}>
                Amount
              </InputLabel>
              <Select
                disabled={!loading && !enabled ? false : true}
                labelId="top_up"
                id="top_up"
                label="Period"
                value={amount}
                onChange={this.handleChange}>
                <MenuItem value={'10'}>10 USD</MenuItem>
                <MenuItem value={'20'}>20 USD</MenuItem>
                <MenuItem value={'50'}>50 USD</MenuItem>
                <MenuItem value={'100'}>100 USD</MenuItem>
                <MenuItem value={'200'}>200 USD</MenuItem>
                <MenuItem value={'500'}>500 USD</MenuItem>
                <MenuItem value={'1000'}>1000 USD</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disabled={true}
                inputVariant="outlined"
                style={{ marginRight: '20px', marginLeft: '15px' }}
                autoOk
                value={startDate}
                onChange={this.handleStartDateChange}
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="start-date"
                keyboardbuttonprops={{
                  'aria-label': 'start-date'
                }}
                label={'Last Payment Date'}
              />
              <DatePicker
                disabled={true}
                inputVariant="outlined"
                autoOk
                value={
                  (startDate.getMonth() === 11
                    ? startDate.getFullYear() + 1
                    : startDate.getFullYear()) +
                  '-' +
                  (startDate.getMonth() === 11 ? 1 : startDate.getMonth() + 2) +
                  '-' +
                  startDate.getDate()
                }
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="next-date"
                keyboardbuttonprops={{
                  'aria-label': 'start-date'
                }}
                label={'Next Charge Date'}
              />
            </MuiPickersUtilsProvider>
          </CardContent>
          <Divider />

          <CardContent>
            <Typography gutterBottom>
              <b>Payment method</b>
            </Typography>
            <RadioGroup aria-label="payment" id="payment" value={value_radio}>
              <FormControlLabel
                value="paypal"
                control={<Radio />}
                label={<img src="/images/secured-payment.png" alt="Paypal" />}
              />
            </RadioGroup>
          </CardContent>
          <Divider />
          <CardActions>
           <Button
              disabled={!loading ? false : true}
              color="primary"
              variant="outlined"
              onClick={this.handleSave}>
              {enabled? "CANCEL SUBSCRIPTION" : "SUBSCRIBE"}
            </Button>
          </CardActions>
        </form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user_autocharge: state.app_reducer.user_autocharge
});

export default connect(mapStateToProps, {
  getAutoChargeUser,
  loadingFn,
  clearFn
})(AutoCharge);
