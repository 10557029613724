import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { connect } from "react-redux";
import DIDsTable from './DIDsTable';
import BuyDID from './BuyDID';
import { getSimpleCards, getDIDs, loadingFn, clearFn } from "../../actions/appActions";
import { Card, Grid, Button, } from '@material-ui/core';


import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';



const useStyles = theme => ({
    root: {
        paddingTop: "32px",
        paddingRight: "2px",
        paddingLeft: "21px",
        [theme.breakpoints.down('md')]: {
          paddingLeft: "2px",
        }
      }
  });

class DIDs extends React.Component {
    constructor() {
        super();
        this.state = {
          open: false,
          value: null,
          loading: false,
          loadingEx: false
        };
    }


    async UNSAFE_componentWillMount() {

      this.props.clearFn();
      this.props.loadingFn(true);
      await this.props.getSimpleCards();
      const { id, card_nr } = this.props.match.params;
      if (id !== undefined) {
        this.setState({ loading: true, value: {id: id, card_nr: card_nr} })
        await this.props.getDIDs(id);
        this.setState({loading: false})
      }
    }

    handleRefresh = async () => {
      const { value } = this.state;
      this.setState({loading: true});
      if (value) {
        await this.props.getDIDs(value.id);
      }
      this.setState({loading: false});
    }

    onSubmit = async e => {
      e.preventDefault();
      const { value } = this.state;
      this.setState({loading: true})
      this.props.clearFn();
      await this.props.getDIDs(value.id);
      this.setState({loading: false})
    }

    onExport = async e => {
      e.preventDefault();
      const { value } = this.state;
      const { dids } = this.props;
      if (dids.length !== 0 ){
        this.setState({loadingEx: true})

        let fileName = 'DIDs_'+value.card_nr;

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(dids);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        this.setState({loadingEx: false})
      }
    }

    handleCloseDID = async () => {
      const { value } = this.state;
      this.setState({ open: false });
      this.setState({loading: true});
      if (value) {
        await this.props.getDIDs(value.id);
      }
      this.setState({loading: false});
    };



    render() {
        const { dids, cards_simple, classes } = this.props;
        const { value,  loading, loadingEx, open } = this.state;

        const rows = dids.map(row => ({ id: row.id, CLI: row.CLI === 'no' ? false: true, area: row.area, auto_renew: row.auto_renew === 0 ? false : true, card_id: row.card_id, cost: row.cost, country: row.country, did: row.did, expire_date: row.expire_date, months: row.months, start_date: row.start_date, status: row.status, card_nr: row.card_nr, country_id: row.country_id}));

        const headCells = [
            { id: 'card_nr', numeric: false, disablePadding: true, label: 'Card' },
            { id: 'did', numeric: true, disablePadding: true, label: 'Number' },
            { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
            { id: 'auto_renew', numeric: false, disablePadding: true, label: 'Auto Renew' },
            { id: 'CLI', numeric: false, disablePadding: true, label: 'CLI' },
            { id: 'months', numeric: false, disablePadding: true, label: 'Months' },
            { id: 'cost', numeric: false, disablePadding: true, label: 'Cost' },
            { id: 'country', numeric: false, disablePadding: true, label: 'Country' },
            { id: 'area', numeric: false, disablePadding: true, label: 'Area' },
            { id: 'start_date', numeric: false, disablePadding: true, label: 'Start' },
            { id: 'expire_date', numeric: false, disablePadding: true, label: 'Expire' },
            { id: 'actions', numeric: false, disablePadding: true, label: 'Actions' }
          ];

        return (
            <div className={classes.root}>
              <BuyDID open={open} handleClose={this.handleCloseDID} card={value}/>
                <Typography variant="h4" style={{paddingLeft: "10px", paddingBottom: "10px"}}>Local DIDs numbers / {value !== null ? value.card_nr : "no Selected"}</Typography>
                <Grid
                  item
                  lg={12} md={12} xl={12} xs={12}
                >
                  <Card>
                  <Grid container justify="space-around" style={{paddingBottom: '10px'}}>
                    <Autocomplete
                        disabled={loading || loadingEx ? true : false}
                        id="cards"
                        onChange={(event, newValue) => {
                          this.setState({value: newValue})
                        }}
                        options={cards_simple}
                        value={value}
                        getOptionSelected={(option, value) => option.card_nr === value.card_nr}
                        getOptionLabel={(option) => option.card_nr}
                        style={{ width: 200, paddingTop: '15px' }}
                        renderInput={(params) => <TextField {...params} label="Choose Card" variant="outlined" />}
                      />
                      <Button
                        disabled={loading || loadingEx || value === null ? true : false}
                        style={{ margin: "14px"}}
                        color="primary"
                        variant="contained"
                        onClick={this.onSubmit}
                      >
                        Check
                      </Button>
                      <Button
                        disabled={loading || loadingEx  || value === null ? true : false}
                        style={{ margin: "14px"}}
                        variant="contained"
                        onClick={this.onExport}
                      >
                        Export
                      </Button>
                      <Button
                        disabled={loading || loadingEx || value === null ? true : false}
                        style={{ margin: "14px"}}
                        color="secondary"
                        variant="contained"
                        onClick={() => this.setState({ open: true })}
                      >
                       Buy New Number
                      </Button>
                    </Grid>
                  </Card>
                </Grid>
              <Grid
                container
                spacing={1}
              >
                <Grid
                item
                lg={12} md={12} xl={12} xs={12}
                >
                  <Card>
                    <DIDsTable headCells={headCells} rows={rows} loading={loading} handleRefresh={this.handleRefresh}/>
                  </Card>
                </Grid>
               </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cards_simple: state.app_reducer.cards_simple,
    dids: state.app_reducer.dids
    });

export default withStyles(useStyles)(connect( mapStateToProps,
    { getSimpleCards, getDIDs, loadingFn, clearFn })(DIDs));
