import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout } from './layouts';

import {
  Cards as CardsView,
  Cdrs as CdrsView,
  Dids as DidsView,
  Orders as OrdersView,
  Bundles as BundlesView,
  Payments as PaymentsView,
  Settings as SettingsView,
  NotFound as NotFoundView
} from './views';

const AuthRoutes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={CardsView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={CardsView}
        exact
        layout={MainLayout}
        path="/login"
      />
      <RouteWithLayout
        component={CardsView}
        exact
        layout={MainLayout}
        path="/cards"
      />
      <RouteWithLayout
        component={CdrsView}
        exact
        layout={MainLayout}
        path="/cdrs"
      />
      <RouteWithLayout
        component={CdrsView}
        exact
        layout={MainLayout}
        path="/cdrs/:id/:card_nr/"
      />
      <RouteWithLayout
        component={DidsView}
        exact
        layout={MainLayout}
        path="/dids"
      />
       <RouteWithLayout
        component={DidsView}
        exact
        layout={MainLayout}
        path="/dids/:id/:card_nr/"
      />
      <RouteWithLayout
        component={OrdersView}
        exact
        layout={MainLayout}
        path="/orders"
      />
      <RouteWithLayout
        component={PaymentsView}
        exact
        layout={MainLayout}
        path="/payments"
      />
      <RouteWithLayout
        component={PaymentsView}
        exact
        layout={MainLayout}
        path="/payments/:id/:card_nr/"
      />
      <RouteWithLayout
        component={PaymentsView}
        exact
        layout={MainLayout}
        path="/payments/:pattern"
      />
      <RouteWithLayout
        component={BundlesView}
        exact
        layout={MainLayout}
        path="/bundles"
      />
      <RouteWithLayout
        component={BundlesView}
        exact
        layout={MainLayout}
        path="/bundles/:id"
      />
      <RouteWithLayout
        component={PaymentsView}
        exact
        layout={MainLayout}
        path="/topup/:id"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default AuthRoutes;
