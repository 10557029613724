import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactCountryFlag from "react-country-flag";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import ListItemText from "@material-ui/core/ListItemText";

import { connect } from 'react-redux';
import { buyDID, loadingFn, clearFn, getDIDCountries, getAreas } from '../../actions/appActions';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class BuyDID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading_area: true,
      activeStep: 0,
      setupFee: 0.00,
      monthlyFee: 0.00,
      label: "",
      value_area: null,
      countryCode: "",
      countryId: 1,
      data_countries: [],
      data_areas: [],
      period: 1,
      enable_period: true,
      auto_renew: false
    };
  }

  async UNSAFE_componentWillMount() {
    //this.props.clearFn();
   // this.props.loadingFn(true);
    await this.props.getDIDCountries();
    //this.setState({loading: false})
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.did_countries.length !== 0) {
      this.setState({
        data_countries: nextProps.did_countries.map(d => ({
          id: d.id,
          label: d.country_name,
          country_code: d.country_code,
          country_phonecode: d.country_phonecode,
          setup_fee: d.setup_fee,
          monthly_fee: d.monthly_fee
        }))
      });
    }
    if (nextProps.did_areas.length !== 0) {
      this.setState({
        loading_area: false,
        data_areas: nextProps.did_areas.map(d => ({
          local_id: d.local_id ? d.local_id : -1,
          code: d.code,
          name: d.name,
          label: d.name
        }))
      });
    }
  }


  getSteps() {
    return ['Select Country', 'Select Area', 'Select Period'];
  }

  handleSave = async (e) => {
    e.preventDefault();
    const { countryId, value_area, period, auto_renew} = this.state;
    const { card } = this.props;

    this.props.clearFn();
    this.setState({
      loading: true,
      enable_period: true,
      loading_area: true
    });
    this.props.loadingFn(true);

    await this.props.buyDID(countryId, value_area.code, value_area.name, value_area.local_id, period, auto_renew, card.id);

    this.setState({
      loading: false,
      loading_area: true,
      activeStep: 0,
      setupFee: 0.00,
      monthlyFee: 0.00,
      label: "",
      value_area: null,
      countryCode: "",
      countryId: 1,
      data_countries: [],
      data_areas: [],
      period: 1,
      enable_period: true,
      auto_renew: false
    });
    this.props.handleClose();
  };

  onCountryChange = async (e, value) => {
    if (value === null) {
      this.setState({activeStep: 0, data_areas: [], loading_area: true, value_area: null, enable_period: true});
      return;
    } else {
        this.setState({ loading: true, data_areas: [], activeStep: 0 , value_area: null,  enable_period: true});
        let result = this.state.data_countries.filter(d => d.id === value.id);
        await this.props.getAreas(value.id);
        await this.setState({
            activeStep: 1,
            loading: false,
            setupFee: result[0].setup_fee,
            monthlyFee: result[0].monthly_fee,
            label: result[0].label,
            countryCode: result[0].country_code,
            countryId: value.id
        });
    }
  };

  onAreaChange = async (e, value) => {
    if (value === null) {
      this.setState({ value_area: value, activeStep: 1, enable_period: true});
      return;
    } else {
        this.setState({ loading: true, value_area: value,  activeStep: 2,  enable_period: false});
        this.setState({ loading: false });
    }
  };

  onChangePeriod = async (e) => {
    this.setState({ period: e.target.value });
  };

  onAutoRenew = (e) => {
    this.setState({auto_renew: e.target.checked})
  };

  onClose = () => {
    this.setState( {
      loading: false,
      loading_area: true,
      activeStep: 0,
      setupFee: 0.00,
      monthlyFee: 0.00,
      label: "",
      value_area: null,
      countryCode: "",
      countryId: 1,
      data_countries: [],
      data_areas: [],
      period: 1,
      enable_period: true,
      auto_renew: false
    });
    this.props.handleClose();
  }


  render() {
    const { open, card } = this.props;
    const { loading, activeStep, data_countries, data_areas, loading_area, value_area, period, enable_period, auto_renew , setupFee, monthlyFee} = this.state;
    const steps = this.getSteps(activeStep);
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth='sm'
          onClose={this.onClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.onClose}
            style={{ backgroundColor: '#3f51b5' }}>
            <b style={{ color: 'white' }}>
              BUY LOCAL NUMBER DID for card - {card === null ? '' : card.card_nr}
            </b>
          </DialogTitle>
          <DialogContent dividers>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
            <Typography gutterBottom>
              <b>SELECT COUNTRY: </b>
            </Typography>
            <div>
            <Autocomplete
              onChange={this.onCountryChange}
              loading={loading}
              options={data_countries}
              getOptionSelected={(option) => option.id}
              getOptionLabel={option => '('+option.country_phonecode+') '+option.label}
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "5px"
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Country"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              renderOption={option => {
                return (
                  <React.Fragment>
                    <Typography> ({option.country_phonecode}) &nbsp;</Typography>
                    <ReactCountryFlag countryCode={option.country_code} svg />
                    <Typography> &nbsp; {option.label} / Month -  <b>{option.monthly_fee} $</b></Typography>
                  </React.Fragment>
                );
              }}
            />

            <Typography gutterBottom style={{marginTop: '10px'}}>
              <b>SELECT LOCAL COUNTRY AREA:</b>
            </Typography>
            <div>
            <Autocomplete
              disabled={loading_area}
              onChange={this.onAreaChange}
              loading={loading}
              options={data_areas}
              value={value_area}
              getOptionSelected={(option) => option.name}
              getOptionLabel={option => '('+option.code+') '+option.name}
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "5px"
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Area"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
              renderOption={option => {
                return (
                  <React.Fragment>
                    <Typography>({option.code}) - {option.name}</Typography>
                  </React.Fragment>
                );
              }}
            />
          </div>
          <Typography gutterBottom style={{marginTop: '10px'}}>
              <b>SELECT TIME PERIOD YOU WANT TO USE:</b>
          </Typography>
          <div style={{marginTop: '10px'}}>

          <FormControl
              variant="outlined"
          >
              <InputLabel
                id="period"
          >
                Period
              </InputLabel>
              <Select
               style={{width: '200px'}}
                disabled={enable_period}
                labelId="period"
                id="period"
                label="Period"
                value={period}
                onChange={this.onChangePeriod}>
                <MenuItem value={1}>1 Month</MenuItem>
                <MenuItem value={3}>3 Months </MenuItem>
                <MenuItem value={6}>6 Months</MenuItem>
                <MenuItem value={12}>12 Months</MenuItem>
              </Select>

            </FormControl>

            <FormControlLabel
            style={{marginLeft: '10px'}}
              control={
                <Switch
                  disabled={enable_period}
                  checked={auto_renew}
                  onChange={this.onAutoRenew}
                  color="primary"
                  name="enabled"
                  inputProps={{ 'aria-label': 'checkbox enabled' }}
                />
              }
              label="Auto Renew"
            />
          </div>
          <div  style={{marginTop: '10px'}}>
          <Typography gutterBottom style={{marginTop: '10px'}}>
              <b>ORDER REVIEW:</b>
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <ListItemText
                          primary={'SETUP FEE'}
                      />
                    </TableCell>
                    <TableCell align="right">{parseFloat(setupFee).toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <ListItemText
                          primary={'Monthly FEE x '+period}
                      />
                    </TableCell>
                    <TableCell align="right">{parseFloat(monthlyFee * period).toFixed(2)}</TableCell>
                  </TableRow>
                <TableRow>
                  <TableCell align="right"><Typography variant="h4"><b>Total</b></Typography></TableCell>
                  <TableCell align="right"><Typography variant="h4">
                      <b>$ {parseFloat((monthlyFee * period) + parseFloat(setupFee)).toFixed(2)}</b>
                    </Typography></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </div>
    </div>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={this.onClose}
              color="primary"
              disabled={loading ? true : false}>
              Cancel
            </Button>
            <Button
              disabled={enable_period}
              autoFocus
              onClick={this.handleSave}
              color="secondary">
              BUY
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error_axios: state.app_reducer.error_axios,
  did_countries: state.app_reducer.did_countries,
  did_areas: state.app_reducer.did_areas
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { loadingFn, clearFn, buyDID, getDIDCountries, getAreas })(BuyDID)
);
