import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Minimal as MinimalLayout } from './layouts';
import { MinimalAdmin as MinimalAdminLayout } from './layouts';

import {
  SignIn as SignInView,
  SignUp as SignUpView,
  ForgotPass as ForgotPassView
} from './views';

import {
  SignIn as SignInViewAdmin,
  ForgotPass as ForgotPassViewAdmin
} from './viewsAdmin';

const RoutesAdmin = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={SignInViewAdmin}
        exact
        layout={MinimalAdminLayout}
        path="/admin"
      />
      <RouteWithLayout
        component={SignInViewAdmin}
        exact
        layout={MinimalAdminLayout}
        path="/admin/login"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/signup"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/signup/:token/"
      />
      <RouteWithLayout
        component={ForgotPassView}
        exact
        layout={MinimalLayout}
        path="/forgotpass"
      />
      <RouteWithLayout
        component={ForgotPassView}
        exact
        layout={MinimalLayout}
        path="/forgotpass/:token/"
      />
      <RouteWithLayout
        component={ForgotPassViewAdmin}
        exact
        layout={MinimalAdminLayout}
        path="/admin/forgotpass"
      />
      <RouteWithLayout
        component={ForgotPassViewAdmin}
        exact
        layout={MinimalAdminLayout}
        path="/admin/forgotpass/:token/"
      />
      <Redirect to="/admin" />
    </Switch>
  );
};

export default RoutesAdmin;
