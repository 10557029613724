import {
  ERROR_AXIOS,
  AUTH_APP,
  AUTH_APP_LOGOUT,
  APP_LOADING,
  SM_LOADING,
  OD_LOADING,
  CLEAR_RESPONSE,
  SIGNUP_APP,
  CHECK_SIGNUP_TOKEN,
  GET_CARDS,
  GET_SIMPLE_CARDS,
  GET_CDRS,
  SET_VOICEMAIL,
  GET_BALANCE,
  GET_PAYMENTS,
  SET_TOPUP,
  REGISTER_CARD,
  FORGOT_PASS,
  RESET_PASS,
  SET_AUTOCHARGE,
  GET_AUTOCHARGE_USER,
  CANCEL_AUTOCHARGE_USER,
  SAVE_AUTOCHARGE_USER,
  GET_DID_COUNTRIES,
  GET_AREAS,
  BUY_DID,
  GET_DIDS,
  SET_CLI,
  SET_AUTORENEW_DID,
  SET_AUTORENEWCHARGE_DID,
  CANCEL_DID
} from './types';

import axios from 'axios';

export function login_admin(data) {
  const content = {
    email: data.email,
    password: data.password
  };
  return (dispatch) => {
    return axios
      .post('https://sip1.easyvoipcall.com/pbxres/api/login_admin_360', content)
      .then((res) => {
        localStorage.setItem('jwtToken', res.data.token);
        localStorage.setItem('jwtTokenid', res.data.tokenid);
        localStorage.setItem('first_name', res.data.first_name);
        localStorage.setItem('last_name', res.data.last_name);
        dispatch({
          type: AUTH_APP,
          payload: res.data.admin
        });
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 404) {
            return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
  };
}
//app functions
export function login(data) {
  const content = {
    email: data.email,
    password: data.password
  };
  return (dispatch) => {
    return axios
      .post('https://sip1.easyvoipcall.com/pbxres/api/login_360', content)
      .then((res) => {
        localStorage.setItem('jwtToken', res.data.token);
        localStorage.setItem('jwtTokenid', res.data.tokenid);
        localStorage.setItem('first_name', res.data.first_name);
        localStorage.setItem('last_name', res.data.last_name);
        dispatch({
          type: AUTH_APP
        });
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 404) {
            return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
  };
}
//Forgot pass
export const forgotpass = (data) => async (dispatch) => {
  const content = {
    email: data.email
  };
  await axios
    .post('https://sip1.easyvoipcall.com/pbxres/api/forgot_pass_360', content)
    .then((res) => {
      return dispatch({
        type: FORGOT_PASS,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        if (e.response.status === 401) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};
//password reset
export const passwordreset = (data, token) => async (dispatch) => {
  const content = {
    password: data.password,
    token: token
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/password_reset_360',
      content
    )
    .then((res) => {
      return dispatch({
        type: RESET_PASS,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        if (e.response.status === 401) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

//signup
export function check_signup_token(token) {
  const content = {
    token: token
  };
  return (dispatch) => {
    return axios
      .post(
        'https://sip1.easyvoipcall.com/pbxres/api/signup_360_check',
        content
      )
      .then((res) => {
        dispatch({
          type: CHECK_SIGNUP_TOKEN,
          payload: res.data
        });
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 404) {
            return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
  };
}
export function signup(data) {
  const content = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email
  };
  return (dispatch) => {
    return axios
      .post('https://sip1.easyvoipcall.com/pbxres/api/signup_360', content)
      .then((res) => {
        dispatch({
          type: SIGNUP_APP,
          payload: res.data
        });
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 404) {
            return dispatch({
              type: ERROR_AXIOS,
              payload: e.message
            });
          }
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.data
          });
        } else {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
      });
  };
}

export function logout() {
  const content = {
    token: localStorage.getItem('jwtToken'),
    tokenid: localStorage.getItem('jwtTokenid'),
    first_name: localStorage.getItem('first_name'),
    last_name: localStorage.getItem('last_name')
  };
  const header = {
    Authorization: 'Bearer ' + content.token
  };
  return (dispatch) => {
    return axios
      .post(
        'https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv',
        content.token ? content.token : null,
        content.token ? { headers: header } : null
      )
      .then(() => {
        localStorage.removeItem('jwtToken', content.token);
        localStorage.removeItem('jwtTokenid', content.tokenid);
        localStorage.removeItem('first_name', content.first_name);
        localStorage.removeItem('last_name', content.last_name);
        dispatch({
          type: AUTH_APP_LOGOUT
        });
      })
      .catch(() => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('jwtTokenid', content.tokenid);
        localStorage.removeItem('first_name', content.first_name);
        localStorage.removeItem('last_name', content.last_name);
        dispatch({
          type: AUTH_APP_LOGOUT
        });
      });
  };
}
export const checkAuth = (token, id) => (dispatch) => {
  const header = {
    Authorization: 'Bearer ' + token
  };
  const content = {
    id: id
  };
  axios
    .post('https://sip1.easyvoipcall.com/pbxres/api/checkauth_360', content, {
      headers: header
    })
    .then((res) => {
      dispatch({
        type: AUTH_APP,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        if (e.response.status === 401) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};
export function loadingFn(loading) {
  return (dispatch) => {
    dispatch({
      type: APP_LOADING,
      payload: loading
    });
  };
}
export function loadingFnOd(loading) {
  return (dispatch) => {
    dispatch({
      type: OD_LOADING,
      payload: loading
    });
  };
}
export function loadingFnSm(loading) {
  return (dispatch) => {
    dispatch({
      type: SM_LOADING,
      payload: loading
    });
  };
}
export function clearFn() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE
    });
  };
}
//register card
export const registerCard = (card, puk) => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    card: card,
    puk: puk
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/register_card_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: REGISTER_CARD,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        if (e.response.status === 401) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

// balance
export const getBalance = () => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid')
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post('https://sip1.easyvoipcall.com/pbxres/api/get_balance_360', content, {
      headers: header
    })
    .then((res) => {
      return dispatch({
        type: GET_BALANCE,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

// payments
export const getPayments = (id, startDate, endDate) => async (dispatch) => {
  let start =
    startDate.getFullYear() +
    '-' +
    (startDate.getMonth() + 1) +
    '-' +
    startDate.getDate();
  let end =
    endDate.getFullYear() +
    '-' +
    (endDate.getMonth() + 1) +
    '-' +
    endDate.getDate();
  const content = {
    id_client: localStorage.getItem('jwtTokenid'),
    id_card: id,
    start: start,
    end: end
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_payments_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type: GET_PAYMENTS,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

// cdrs
export const getCDRs = (id, startDate, endDate) => async (dispatch) => {
  let start =
    startDate.getFullYear() +
    '-' +
    (startDate.getMonth() + 1) +
    '-' +
    startDate.getDate();
  let end =
    endDate.getFullYear() +
    '-' +
    (endDate.getMonth() + 1) +
    '-' +
    endDate.getDate();
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    id_card: id,
    start: start,
    end: end
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post('https://sip1.easyvoipcall.com/pbxres/api/get_cdrs_360', content, {
      headers: header
    })
    .then((res) => {
      return dispatch({
        type: GET_CDRS,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};
// user auto charge
export const getAutoChargeUser = () => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid')
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_autocharge_user_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type: GET_AUTOCHARGE_USER,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const saveAutoChargeUser = (
  details
) => async (dispatch) => {


  const content = {
    id: localStorage.getItem('jwtTokenid'),
    details: details
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/save_autocharge_user_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: SAVE_AUTOCHARGE_USER,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const cancelAutoChargeUser = (
  amount,
  startDate,
  nextDate,
  enabled
) => async (dispatch) => {
  let start =
    startDate.getFullYear() +
    '-' +
    (startDate.getMonth() + 1) +
    '-' +
    startDate.getDate();

  const content = {
    id: localStorage.getItem('jwtTokenid'),
    amount: amount,
    start_date: start,
    next_date: nextDate,
    enabled: enabled
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/cancel_autocharge_user_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: CANCEL_AUTOCHARGE_USER,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

// Cards
export const getSimpleCards = () => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid')
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_simple_cards_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type: GET_SIMPLE_CARDS,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const getCards = (offset, limit) => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    offset: offset,
    limit: limit
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post('https://sip1.easyvoipcall.com/pbxres/api/get_cards_360', content, {
      headers: header
    })
    .then((res) => {
      return dispatch({
        type: GET_CARDS,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const setAutoCharge = (
  card_id,
  amount,
  card_nr,
  startDate,
  nextDate,
  enabled
) => async (dispatch) => {
  let start =
    startDate.getFullYear() +
    '-' +
    (startDate.getMonth() + 1) +
    '-' +
    startDate.getDate();

  const content = {
    id: localStorage.getItem('jwtTokenid'),
    id_card: card_id,
    amount: amount,
    card_nr: card_nr,
    start_date: start,
    next_date: nextDate,
    enabled: enabled
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/set_autocharge_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: SET_AUTOCHARGE,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const setTopUp = (card_id, amount, card_nr) => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    id_card: card_id,
    amount: amount,
    card_nr: card_nr
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post('https://sip1.easyvoipcall.com/pbxres/api/set_topup_360', content, {
      headers: header
    })
    .then((res) => {
      return dispatch({
        type: SET_TOPUP,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const setVoicemail = (card_nr, timeout, voicemail) => async (
  dispatch
) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    card_nr: card_nr,
    timeout: timeout,
    voicemail: voicemail
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/set_voicemail_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type: SET_VOICEMAIL,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

//DIDs

export const getDIDs = (id_card) => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    id_card: id_card
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_dids_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: GET_DIDS,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const getDIDCountries = () => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_countries_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: GET_DID_COUNTRIES,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const getAreas = (id) => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    country_id: id
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_areas_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: GET_AREAS,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const buyDID = (country_id, area_code, area_name, area_local_id, months, auto_renew, card_id) => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    country_id: country_id,
    area_code: area_code,
    area_name: area_name,
    area_local_id: area_local_id,
    months: months,
    auto_renew: auto_renew,
    card_id: card_id
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/buy_did_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: BUY_DID,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response.data) {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const setCLI = (card_nr, cli) => async (
  dispatch
) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    card_nr: card_nr,
    cli: cli
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/set_cli_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type: SET_CLI,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const setAutoRenew = (card_nr, auto_renew, did) => async (
  dispatch
) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    card_nr: card_nr,
    auto_renew: auto_renew,
    did: did
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/set_autorenew_did_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type:  SET_AUTORENEW_DID,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};
export const setAutoRenewCharge = (card_id, auto_renew, did, period, country_id) => async (
  dispatch
) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    card_id: card_id,
    auto_renew: auto_renew,
    did: did,
    country_id: country_id,
    months: period
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/set_autorenewcharge_did_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type:  SET_AUTORENEWCHARGE_DID,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response.data) {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};
export const cancelDID = (card_nr, did) => async (
  dispatch
) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    card_nr: card_nr,
    did: did
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/cancel_did_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type:  CANCEL_DID,
        payload: res.data
      });
    })
    .catch((e) => {
      if (e.response.data) {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};
