import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import CardsTable from './CardsTable';
import { getCards, loadingFn, clearFn } from '../../actions/adminActions';

const useStyles = (theme) => ({
  root: {
    paddingTop: '32px',
    paddingRight: '2px',
    paddingLeft: '21px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2px'
    }
  }
});

class Cards extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  async handleRefresh() {
    //this.props.loadingFn(true);
    // await this.props.getCards(offset, limit);
  }

  async UNSAFE_componentWillMount() {
    this.props.clearFn();
    this.props.loadingFn(true);
    const { id } = this.props.match.params;
    if (id !== undefined) {
      await this.props.getCards(id);
    } else {
      await this.props.getCards(null);
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { cards, classes } = this.props;
    const rows = cards.map((row) => ({
      id: row.id,
      card_nr: row.card_nr,
      puk: row.puk,
      order_id: row.order_id,
      user_id: row.user_id,
      email: row.email
    }));

    const headCells = [
      { id: 'user', numeric: false, disablePadding: true, label: 'User' },
      {
        id: 'card_nr',
        numeric: false,
        disablePadding: true,
        label: 'Sim card number'
      },
      { id: 'puk', numeric: false, disablePadding: true, label: 'PUK' },
      {
        id: 'order_id',
        numeric: false,
        disablePadding: true,
        label: 'Order'
      }
    ];
    return (
      <div className={classes.root}>
        <Typography
          variant="h4"
          style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          SIM Cards
        </Typography>
        <Grid container spacing={1}>
          <Grid item lg={8} md={12} xl={8} xs={12}>
            <CardsTable
              headCells={headCells}
              rows={rows}
              handleRefresh={this.handleRefresh}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cards: state.admin_reducer.cards
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { getCards, loadingFn, clearFn })(Cards)
);
