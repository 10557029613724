import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { connect } from 'react-redux';
import { setAutoCharge, loadingFn, clearFn } from '../../actions/appActions';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
});

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class AutoCharge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      amount: parseFloat(0.0).toFixed(2),
      enabled: false,
      startDate: this.todayStart(),
      errors: {}
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rowselected) {
      this.setState({
        amount: parseFloat(
          nextProps.rowselected.auto_charge !== null
            ? nextProps.rowselected.auto_charge
            : 0.0
        ).toFixed(2),
        enabled: nextProps.rowselected.auto_charge !== null ? true : false
      });
    }
  }

  todayStart() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return new Date(now);
  }

  handleStartDateChange = (date) => {
    const now = this.todayStart();
    if (
      date.getMonth() >= now.getMonth() &&
      date.getFullYear() >= now.getFullYear()
    ) {
      this.setState({ startDate: date });
    } else {
      this.setState({ startDate: now });
    }
  };

  handleSave = async (e) => {
    e.preventDefault();
    const { amount, errors, startDate, enabled } = this.state;
    const { rowselected } = this.props;
    if (
      amount === '' ||
      parseFloat(amount) < parseFloat(0.01) ||
      parseFloat(amount) > parseFloat(this.props.balance)
    ) {
      this.setState({
        errors: { ...errors, amount: true }
      });
      return;
    }
    this.props.clearFn();
    this.setState({
      loading: true
    });
    this.props.loadingFn(true);

    let nextDate =
      (startDate.getMonth() === 11
        ? startDate.getFullYear() + 1
        : startDate.getFullYear()) +
      '-' +
      (startDate.getMonth() === 11 ? 1 : startDate.getMonth() + 2) +
      '-' +
      startDate.getDate();
    //Set auto charge
    await this.props.setAutoCharge(
      rowselected.id,
      amount,
      rowselected.card_nr,
      startDate,
      nextDate,
      enabled
    );
    this.setState({
      loading: false
    });
    this.props.handleClose();
  };

  handleChange = (e) => {
    e.persist();
    const { errors } = this.state;
    this.setState(
      e.target.name === 'enabled'
        ? {
            [e.target.name]: e.target.checked
          }
        : e.target.value === '' ||
          parseFloat(e.target.value) < parseFloat(0.01) ||
          parseFloat(e.target.value) > parseFloat(this.props.balance)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              ...errors,
              [e.target.id]: true
            }
          }
        : {
            [e.target.id]: e.target.value,
            errors: {
              ...errors,
              [e.target.id]: false
            }
          }
    );
  };

  render() {
    const { handleClose, open, balance, rowselected } = this.props;
    const { loading, errors, amount, startDate, enabled } = this.state;

    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ backgroundColor: '#3f51b5' }}>
            <b style={{ color: 'white' }}>
              SET AUTO CHARGE - {rowselected.card_nr}
            </b>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <b>Please select the amount for monthly AUTO CHARGE.</b>
            </Typography>
            <Typography gutterBottom>
              This function will automatically top up your card from your
              account balance.
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={enabled}
                  onChange={this.handleChange}
                  color="primary"
                  name="enabled"
                  inputProps={{ 'aria-label': 'checkbox enabled' }}
                />
              }
              label="Enabled"
            />
            <TextField
              disabled={!loading && enabled ? false : true}
              fullWidth
              label="Amount"
              name="amount"
              id="amount"
              type="number"
              onChange={this.handleChange}
              onFocus={this.handleChange}
              onBlur={this.handleChange}
              style={{ marginTop: '1rem' }}
              value={amount}
              InputProps={{
                inputProps: { min: 0.01, max: balance, step: '.01' }
              }}
              variant="outlined"
              error={errors.amount ? true : false}
              helperText={
                errors.amount
                  ? 'Wrong value please use beetwen 0.01 and ' + balance
                  : null
              }
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disabled={!loading && enabled ? false : true}
                inputVariant="outlined"
                style={{ width: 200, marginRight: '20px' }}
                autoOk
                value={startDate}
                onChange={this.handleStartDateChange}
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="start-date"
                keyboardbuttonprops={{
                  'aria-label': 'start-date'
                }}
                label={'Start Date'}
              />
              <DatePicker
                disabled={true}
                inputVariant="outlined"
                style={{ width: 200 }}
                autoOk
                value={
                  (startDate.getMonth() === 11
                    ? startDate.getFullYear() + 1
                    : startDate.getFullYear()) +
                  '-' +
                  (startDate.getMonth() === 11 ? 1 : startDate.getMonth() + 2) +
                  '-' +
                  startDate.getDate()
                }
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="next-date"
                keyboardbuttonprops={{
                  'aria-label': 'start-date'
                }}
                label={'Next Charge Date'}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              disabled={loading ? true : false}>
              Cancel
            </Button>
            <Button
              disabled={!loading ? false : true}
              autoFocus
              onClick={this.handleSave}
              color="secondary">
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error_axios: state.app_reducer.error_axios
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { loadingFn, clearFn, setAutoCharge })(AutoCharge)
);
