import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  colors,
  Typography
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';

import { connect } from 'react-redux';
import { logout, loadingFn, clearFn } from '../../../../actions/appActions';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  active: {
    color: colors.lightBlue[200]
  },
  balance: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    }
  }

}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const Topbar = (props) => {
  const {
    className,
    onSidebarOpen,
    logout,
    loadingFn,
    clearFn,
    balance,
    ...rest
  } = props;
  const classes = useStyles();

  const onClick = (event) => {
    event.preventDefault();
    clearFn();
    loadingFn(true);
    logout();
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src="/images/afas.png" style={{ width: '150px' }} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Tooltip title={'TOP UP your account'}>
          <IconButton
            component={CustomRouterLink}
            color="inherit"
            to="/payments">
            <Typography className={classes.balance} variant="h3" style={{ color: 'white' }}>
              {balance} USD
            </Typography>
          </IconButton>
        </Tooltip>

        <Hidden mdDown>
          <Tooltip title={'Logout'}>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={onClick}>
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default connect(null, { logout, loadingFn, clearFn })(Topbar);
