import {
  APP_LOADING_ADMIN,
  CLEAR_RESPONSE_ADMIN,
  ERROR_AXIOS,
  ERROR_AXIOS_ADMIN,
  FORGOT_PASS,
  RESET_PASS,
  GET_SIMPLE_CARDS_ADMIN,
  GET_SIMPLE_USERS_ADMIN,
  GET_CARDS_ADMIN,
  GET_USERS_ADMIN,
  GET_PAYMENTS_ADMIN
} from './types';
import axios from 'axios';

export function clearFn() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_RESPONSE_ADMIN
    });
  };
}

export function loadingFn(loading) {
  return (dispatch) => {
    dispatch({
      type: APP_LOADING_ADMIN,
      payload: loading
    });
  };
}

//Forgot pass
export const forgotpass = (data) => async (dispatch) => {
  const content = {
    email: data.email
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/forgot_pass_admin_360',
      content
    )
    .then((res) => {
      return dispatch({
        type: FORGOT_PASS,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        if (e.response.status === 401) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};
//password reset
export const passwordreset = (data, token) => async (dispatch) => {
  const content = {
    password: data.password,
    token: token
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/password_reset_360',
      content
    )
    .then((res) => {
      return dispatch({
        type: RESET_PASS,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.message
          });
        }
        if (e.response.status === 401) {
          return dispatch({
            type: ERROR_AXIOS,
            payload: e.response.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.response.data
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS,
          payload: e.message
        });
      }
    });
};

export const getUsers = () => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid')
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_users_admin_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: GET_USERS_ADMIN,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS_ADMIN,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.message
        });
      }
    });
};

export const getCards = (user_id) => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid'),
    id_user: user_id
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_cards_admin_360',
      content,
      {
        headers: header
      }
    )
    .then((res) => {
      return dispatch({
        type: GET_CARDS_ADMIN,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS_ADMIN,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.message
        });
      }
    });
};

// Cards
export const getSimpleCards = () => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid')
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_simple_cards_admin_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type: GET_SIMPLE_CARDS_ADMIN,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS_ADMIN,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.message
        });
      }
    });
};

// Cards
export const getSimpleUsers = () => async (dispatch) => {
  const content = {
    id: localStorage.getItem('jwtTokenid')
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_simple_users_admin_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type: GET_SIMPLE_USERS_ADMIN,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS_ADMIN,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.message
        });
      }
    });
};

// payments
export const getPayments = (user, card, startDate, endDate) => async (
  dispatch
) => {
  let start =
    startDate.getFullYear() +
    '-' +
    (startDate.getMonth() + 1) +
    '-' +
    startDate.getDate();
  let end =
    endDate.getFullYear() +
    '-' +
    (endDate.getMonth() + 1) +
    '-' +
    endDate.getDate();
  const content = {
    id_client: localStorage.getItem('jwtTokenid'),
    id_card: card,
    id_user: user,
    start: start,
    end: end
  };
  const header = {
    Authorization: 'Bearer ' + localStorage.getItem('jwtToken')
  };
  await axios
    .post(
      'https://sip1.easyvoipcall.com/pbxres/api/get_payments_admin_360',
      content,
      { headers: header }
    )
    .then((res) => {
      return dispatch({
        type: GET_PAYMENTS_ADMIN,
        payload: res
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 404) {
          return dispatch({
            type: ERROR_AXIOS_ADMIN,
            payload: e.message
          });
        }
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.response.data.message
        });
      } else {
        return dispatch({
          type: ERROR_AXIOS_ADMIN,
          payload: e.message
        });
      }
    });
};
