import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Admin as MainLayout } from './layouts';

import {
  Cards as CardsView,
  Users as UsersView,
  Orders as OrdersView,
  Payments as PaymentsView,
  Settings as SettingsView,
  NotFound as NotFoundView
} from './viewsAdmin';

const AuthAdminRoutes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={UsersView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={UsersView}
        exact
        layout={MainLayout}
        path="/admin"
      />
      <RouteWithLayout
        component={UsersView}
        exact
        layout={MainLayout}
        path="/admin/users"
      />
      <RouteWithLayout
        component={CardsView}
        exact
        layout={MainLayout}
        path="/admin/login"
      />
      <RouteWithLayout
        component={CardsView}
        exact
        layout={MainLayout}
        path="/admin/cards"
      />
      <RouteWithLayout
        component={CardsView}
        exact
        layout={MainLayout}
        path="/admin/cards/:id"
      />
      <RouteWithLayout
        component={OrdersView}
        exact
        layout={MainLayout}
        path="/admin/orders"
      />
      <RouteWithLayout
        component={OrdersView}
        exact
        layout={MainLayout}
        path="/admin/orders/:id"
      />
      <RouteWithLayout
        component={PaymentsView}
        exact
        layout={MainLayout}
        path="/admin/payments"
      />
      <RouteWithLayout
        component={PaymentsView}
        exact
        layout={MainLayout}
        path="/admin/payments/:id"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/admin/settings"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/admin/not-found"
      />
      <Redirect to="/admin/not-found" />
    </Switch>
  );
};

export default AuthAdminRoutes;
