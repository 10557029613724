import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import { connect } from 'react-redux';
import { loadingFn, clearFn, cancelAutoChargeUser } from '../../../../actions/appActions';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class CancelChargeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleSave = async () => {
    const { amount, startDate } = this.props;
    this.setState({
      loading: true
    });
    this.props.clearFn();
    this.props.loadingFn(true);

    let nextDate =
      (startDate.getMonth() === 11
        ? startDate.getFullYear() + 1
        : startDate.getFullYear()) +
      '-' +
      (startDate.getMonth() === 11 ? 1 : startDate.getMonth() + 2) +
      '-' +
      startDate.getDate();

    await this.props.cancelAutoChargeUser(amount, startDate, nextDate, false);

    this.setState({
      loading: false
    });
    this.props.handleClose();
  };

  render() {
    const { handleClose, open, amount } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ backgroundColor: '#3f51b5' }}>
            <b style={{ color: 'white' }}>Confirmation</b>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <b>You are trying to CANCEL your subscription.</b>
            </Typography>
            <Typography gutterBottom>Please confirm or cancel.</Typography>
            <Alert severity="warning">
              {' '}
              <Typography variant="h5" style={{ color: 'white' }}>
                Subscription Plan: <b>{amount} USD / Month</b>{' '}
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              disabled={loading ? true : false}>
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={this.handleSave}
              color="secondary"
              disabled={loading ? true : false}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error_axios: state.app_reducer.error_axios
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { loadingFn, clearFn, cancelAutoChargeUser })(CancelChargeDialog)
);
