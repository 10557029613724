import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  IconButton
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';
import {
  passwordreset,
  forgotpass,
  loadingFn,
  clearFn
} from '../../actions/appActions';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'reqiored' },
    email: true,
    length: {
      maximum: 256
    }
  }
};

const schema_pass = {
  password: {
    presence: { allowEmpty: false, message: 'reqiored' },
    length: {
      minimum: 8,
      maximum: 20
    }
  },
  confirm_password: {
    presence: { allowEmpty: false, message: 'reqiored' },
    length: {
      minimum: 8,
      maximum: 20,
      equalTo: '#password'
    }
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: 'url(/images/360roaming_login.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
    marginTop: '30%'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const ForgotPass = (props) => {
  const {
    history,
    passwordreset,
    forgotpass,
    error_axios,
    success_axios,
    loading,
    loadingFn,
    clearFn,
    match
  } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    response_error: false,
    response_success: false
  });

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFormState((formState) => ({
      ...formState,
      response_error: false
    }));
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFormState((formState) => ({
      ...formState,
      response_sucess: false
    }));
  };

  const handleBack = () => {
    clearFn();
    history.goBack();
  };

  useEffect(() => {
    let errors = {};
    if (match.params.token !== undefined) {
      errors = validate(formState.values, schema_pass);
    } else {
      errors = validate(formState.values, schema);
    }
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [match, formState.values]);

  useEffect(() => {
    async function clearRedux() {
      await clearFn();
      await handleCloseError();
      await handleCloseSuccess();
    }
    clearRedux();
  }, [clearFn]);

  useEffect(() => {
    if (Object.entries(error_axios).length !== 0) {
      setFormState((formState) => ({
        ...formState,
        response_error: true,
        response_success: false
      }));
    }
    if (Object.entries(success_axios).length !== 0) {
      setFormState((formState) => ({
        ...formState,
        response_error: false,
        response_success: true
      }));
    }
  }, [error_axios, success_axios]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleForgotPass = (event) => {
    event.preventDefault();
    clearFn();
    loadingFn(true);
    forgotpass(formState.values);
  };

  const handleResetPass = (event) => {
    event.preventDefault();
    clearFn();
    loadingFn(true);
    passwordreset(formState.values, match.params.token);
    history.push('/');
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                International Data Roaming
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Connecting People at Anytime & Anywhere.
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={
                  match.params.token !== undefined
                    ? handleResetPass
                    : handleForgotPass
                }>
                <Typography className={classes.title} variant="h2">
                  Reset Password
                </Typography>
                {match.params.token !== undefined ? (
                  <React.Fragment>
                    <TextField
                      className={classes.textField}
                      error={hasError('password')}
                      fullWidth
                      helperText={
                        hasError('password') ? 'wrong Password' : null
                      }
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.password || ''}
                      variant="outlined"
                      disabled={loading ? true : false}
                    />
                    <TextField
                      className={classes.textField}
                      error={hasError('confirm_password')}
                      fullWidth
                      helperText={
                        hasError('confirm_password')
                          ? 'password not equal'
                          : null
                      }
                      label="Confirm password"
                      name="confirm_password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.confirm_password || ''}
                      variant="outlined"
                      disabled={loading ? true : false}
                    />
                  </React.Fragment>
                ) : (
                  <TextField
                    className={classes.textField}
                    error={hasError('email')}
                    fullWidth
                    helperText={hasError('email') ? 'wrong Email' : null}
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ''}
                    variant="outlined"
                    disabled={loading ? true : false}
                  />
                )}
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid || loading ? true : false}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  {loading ? <CircularProgress size={24} /> : 'RESET PASSWORD'}
                </Button>
                <Typography color="textSecondary" variant="body1">
                  <Link component={RouterLink} to="/" variant="h6">
                    Back to login
                  </Link>
                </Typography>
              </form>
              <Snackbar
                open={formState.response_error ? true : false}
                autoHideDuration={6000}
                onClose={handleCloseError}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleCloseError} severity="error">
                  <Typography style={{ color: 'white' }} variant="h4">
                    {error_axios}
                  </Typography>
                </Alert>
              </Snackbar>
              <Snackbar
                open={formState.response_success ? true : false}
                autoHideDuration={6000}
                onClose={handleCloseSuccess}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleCloseSuccess} severity="success">
                  <Typography style={{ color: 'white' }} variant="h4">
                    {success_axios}
                  </Typography>
                </Alert>
              </Snackbar>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ForgotPass.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  error_axios: state.app_reducer.error_axios,
  success_axios: state.app_reducer.success_axios,
  loading: state.app_reducer.loading
});

export default connect(mapStateToProps, {
  passwordreset,
  forgotpass,
  loadingFn,
  clearFn
})(withRouter(ForgotPass));
