import {
  ERROR_AXIOS_ADMIN,
  APP_LOADING_ADMIN,
  SM_LOADING_ADMIN,
  OD_LOADING_ADMIN,
  CLEAR_RESPONSE_ADMIN,
  GET_SIMPLE_CARDS_ADMIN,
  GET_SIMPLE_USERS_ADMIN,
  GET_CARDS_ADMIN,
  GET_USERS_ADMIN,
  GET_PAYMENTS_ADMIN
} from '../actions/types';

const initialState = {
  loading: false,
  loading_sm: false,
  loading_od: false,
  cards: [],
  cards_simple: [],
  users_simple: [],
  users: [],
  orders: [],
  payments: [],
  total_cards: 0,
  error_axios: '',
  success_axios: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SIMPLE_CARDS_ADMIN:
      return {
        ...state,
        cards_simple: action.payload.data,
        loading: false
      };
    case GET_SIMPLE_USERS_ADMIN:
      return {
        ...state,
        users_simple: action.payload.data,
        loading: false
      };
    case GET_CARDS_ADMIN:
      return {
        ...state,
        cards: action.payload.data,
        loading: false
      };
    case GET_PAYMENTS_ADMIN:
      return {
        ...state,
        payments: action.payload.data,
        loading: false
      };
    case GET_USERS_ADMIN:
      return {
        ...state,
        users: action.payload.data,
        loading: false
      };
    case SM_LOADING_ADMIN:
      return {
        ...state,
        loading_sm: action.payload
      };
    case OD_LOADING_ADMIN:
      return {
        ...state,
        loading_od: action.payload
      };
    case CLEAR_RESPONSE_ADMIN:
      return {
        ...state,
        error_axios: '',
        success_axios: ''
      };
    case APP_LOADING_ADMIN:
      return {
        ...state,
        loading: action.payload
      };
    case ERROR_AXIOS_ADMIN:
      return {
        ...state,
        error_axios: action.payload,
        loading: false,
        loading_sm: false,
        loading_od: false,
        success_axios: ''
      };
    default:
      return state;
  }
}
