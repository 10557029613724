import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Shipping = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Update shipping details"
          title="Shipping"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="First Name"
            name="first_name"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.first_name}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Last Name"
            name="last_name"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.last_name}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Company"
            name="company"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.company}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Address"
            name="address"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.address}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="City"
            name="city"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.city}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Post Code"
            name="post_code"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.post_code}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Country"
            name="country"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.country}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="State"
            name="state"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.state}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            onChange={handleChange}
            type="text"
            style={{ marginTop: '1rem' }}
            value={values.phone}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
          >
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Shipping.propTypes = {
  className: PropTypes.string
};

export default Shipping;
