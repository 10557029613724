import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { connect } from "react-redux";
import CdrsTable from './CdrsTable';
import { getSimpleCards, getCDRs, loadingFn, clearFn } from "../../actions/appActions";
import { Card, Grid, Button, } from '@material-ui/core';


import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';



const useStyles = theme => ({
    root: {
        paddingTop: "32px",
        paddingRight: "2px",
        paddingLeft: "21px",
        [theme.breakpoints.down('md')]: {
          paddingLeft: "2px",
        }
      }
  });

class Cdrs extends React.Component {
    constructor() {
        super();
        this.state = {
          value: null,
          startDate:  this.todayStart(),
          endDate: this.todayEnd(),
          period: 1,
          loading: false,
          loadingEx: false
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    async handleRefresh(id) {
        this.props.loadingFn(true);
        await this.props.getCDRs(id);
    }

    handleChangePeriod = (event) => {
      this.setState({period: event.target.value})
      if (event.target.value === 1) {
        this.setState({startDate: this.todayStart()});
        this.setState({endDate: this.todayEnd()});
      }
      if (event.target.value === 2) {
        this.setState({startDate:  this.yesterdyStart()});
        this.setState({endDate: this.yesterdyEnd()});
      }
      if (event.target.value === 3) {
        this.setState({startDate: this.thisWeekStart()});
        this.setState({endDate: this.thisWeekEnd()});
      }
      if (event.target.value === 4) {
        this.setState({startDate: this.lastWeekStart()});
        this.setState({endDate: this.lastWeekEnd()});
      }
      if (event.target.value === 5) {
        this.setState({startDate: this.thisMonthStart()});
        this.setState({endDate: this.thisMonthEnd()});
      }
      if (event.target.value === 6) {
        this.setState({startDate:  this.lastMonthStart()});
        this.setState({endDate:  this.lastMonthEnd()});
      }
    }

    handleStartDateChange = (date) => {
      // cant be lower then end date
      this.setState({startDate: date})
    };

    handleEndDateChange = (date) => {
      // cant be higher then end date
      this.setState({endDate: date})
      // allow only 30 days period
    };

    async handleChange(id) {
      this.props.loadingFn(true);
      await this.props.getCDRs(id);
    }

    lastMonthStart() {
      let now = new Date();
      now = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      return now;
    }

    lastMonthEnd() {
      let now = new Date();
      now.setDate(0);
      now.setHours(23);
      now.setMinutes(59);
      now.setSeconds(59);
      return now;
    }

    thisMonthStart() {
      let now = new Date();
      now =  new Date(now.getFullYear(), now.getMonth(), 1)
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      return now;
    }

    thisMonthEnd() {
      let now = new Date();
      now = new Date(now.getFullYear(), now.getMonth()+1, 0)
      now.setHours(23);
      now.setMinutes(59);
      now.setSeconds(59);
      return now;
    }

    lastWeekStart() {
      let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
      beforeOneWeek.setHours(0);
      beforeOneWeek.setMinutes(0);
      beforeOneWeek.setSeconds(0);
       let day = beforeOneWeek.getDay();
      let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
      return  new Date(beforeOneWeek.setDate(diffToMonday));
    }
    lastWeekEnd() {
      let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
      beforeOneWeek.setHours(23);
      beforeOneWeek.setMinutes(59);
      beforeOneWeek.setSeconds(59);
      let day = beforeOneWeek.getDay();
      let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
      return new Date(beforeOneWeek.setDate(diffToMonday + 6));
    }

    thisWeekStart() {
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      let first = now.getDate() - now.getDay() +1;
      return new Date(now.setDate(first))
    }
    thisWeekEnd() {
      let now = new Date();
      now.setHours(23);
      now.setMinutes(59);
      now.setSeconds(59);
      let first = now.getDate() - now.getDay() +1;
      let last = first + 6;
      return new Date(now.setDate(last))
    }
    todayStart() {
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      return new Date(now);

    }
    todayEnd() {
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setDate(new Date().getDate()+1);
      let nextDay = now.setDate(new Date().getDate()+1)
      return new Date(nextDay);
    }

    yesterdyStart() {
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      let yesterdyStart = now - 1000 * 60 * 60 * 24 * 1;
      return new Date(yesterdyStart);

    }
    yesterdyEnd() {
      let now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      return new Date(now);
    }

    async UNSAFE_componentWillMount() {

      this.props.clearFn();
      this.props.loadingFn(true);
      await this.props.getSimpleCards();
      const { id, card_nr } = this.props.match.params;
      if (id !== undefined) {
        this.setState({ loading: true, value: {id: id, card_nr: card_nr} })
        await this.props.getCDRs(id, this.todayStart(), this.todayEnd());
        this.setState({loading: false})
      }
    }


    onSubmit = async e => {
      e.preventDefault();
      const { value,  startDate, endDate } = this.state;
      this.setState({loading: true})
      this.props.clearFn();
      await this.props.getCDRs(value.id, startDate, endDate);
      this.setState({loading: false})
    }

    onExport = async e => {
      e.preventDefault();
      const { value,  startDate, endDate } = this.state;
      const { cdrs } = this.props;
      if (cdrs.length !== 0 ){
        this.setState({loadingEx: true})
        let start = startDate.getFullYear()  + "-" + (startDate.getMonth()+1) + "-" + startDate.getDate();
        let end = endDate.getFullYear()  + "-" + (endDate.getMonth()+1) + "-" + endDate.getDate();

        let fileName = 'CDRs_'+value.card_nr+'_'+start+'_'+end;

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(cdrs);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        this.setState({loadingEx: false})
      }
    }

    render() {
        const { cdrs, cards_simple, classes } = this.props;
        const { value,  startDate, endDate, period, loading, loadingEx } = this.state;

        const rows = cdrs.map(row => ({ id: row.id, time: row.CREATE_TIME, leg1: row.LEG_1, leg2: row.LEG_2, type: row.TYPE, outcome: row.OUTCOME, duration: row.DURATION, charge: row.CHARGE, leg1_country: row.LEG1_COUNTRY, leg2_country: row.LEG2_COUNTRY}));

        const headCells = [
            { id: 'time', numeric: false, disablePadding: true, label: 'Time' },
            { id: 'leg1', numeric: false, disablePadding: true, label: 'Leg A' },
            { id: 'leg2', numeric: false, disablePadding: true, label: 'Leg B' },
            { id: 'type', numeric: false, disablePadding: true, label: 'Type' },
            { id: 'outcome', numeric: false, disablePadding: true, label: 'Outcome' },
            { id: 'duration', numeric: false, disablePadding: true, label: 'Duration' },
            { id: 'charge', numeric: false, disablePadding: true, label: 'Cost' },
            { id: 'leg1_country', numeric: false, disablePadding: true, label: 'A country' },
            { id: 'leg2_country', numeric: false, disablePadding: true, label: 'B country' },
          ];

        return (
            <div className={classes.root}>
                <Typography variant="h4" style={{paddingLeft: "10px", paddingBottom: "10px"}}>Call detail record / {value !== null ? value.card_nr : "no Selected"}</Typography>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <Card>
                  <Grid container justify="space-around">
                    <Autocomplete
                        disabled={loading || loadingEx ? true : false}
                        id="cards"
                        onChange={(event, newValue) => {
                          this.setState({value: newValue})
                        }}
                        options={cards_simple}
                        value={value}
                        getOptionSelected={(option, value) => option.card_nr === value.card_nr}
                        getOptionLabel={(option) => option.card_nr}
                        style={{ width: 200, paddingTop: '15px' }}
                        renderInput={(params) => <TextField {...params} label="Choose Card" variant="outlined" />}
                      />
                    <FormControl variant="outlined" style={{paddingTop: "15px", width: "200px"}}>
                    <InputLabel id="period" style={{paddingTop: "17px"}}>Period</InputLabel>
                      <Select
                        disabled={loading || loadingEx ? true : false}
                        labelId="period"
                        id="period"
                        label="Period"
                        value={period}
                        onChange={this.handleChangePeriod}
                      >
                        <MenuItem value={1}>Today</MenuItem>
                        <MenuItem value={2}>Yesterday</MenuItem>
                        <MenuItem value={3}>This Week</MenuItem>
                        <MenuItem value={4}>Last Week</MenuItem>
                        <MenuItem value={5}>This Month</MenuItem>
                        <MenuItem value={6}>Last Month</MenuItem>
                        <MenuItem value={7}>Custom</MenuItem>
                      </Select>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disabled={(period === 7 && !loading) || (period === 7 && !loadingEx) ? false : true}
                          inputVariant="outlined"
                          style={{width: 200 }}
                          autoOk
                          value={startDate}
                          onChange={this.handleStartDateChange}
                          variant="inline"
                          format="yyyy-MM-dd HH:mm"
                          margin="normal"
                          id="start-date"
                          keyboardbuttonprops={{
                            'aria-label': 'start-date',
                          }}
                          label={'Start Date'}
                        />
                        <DatePicker
                          disabled={(period === 7 && !loading) || (period === 7 && !loadingEx) ? false : true}
                          inputVariant="outlined"
                          style={{width: 200 }}
                          autoOk
                          value={endDate}
                          onChange={this.handleEndDateChange}
                          variant="inline"
                          format="yyyy-MM-dd HH:mm"
                          margin="normal"
                          id="end-date"
                          keyboardbuttonprops={{
                            'aria-label': 'end-date',
                          }}
                          label={'End Date'}
                        />
                      </MuiPickersUtilsProvider>
                      <Button
                        disabled={loading || loadingEx || value === null ? true : false}
                        style={{ margin: "14px"}}
                        color="primary"
                        variant="contained"
                        onClick={this.onSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        disabled={loading || loadingEx  || value === null ? true : false}
                        style={{ margin: "14px"}}
                        color="secondary"
                        variant="contained"
                        onClick={this.onExport}
                      >
                        Export
                      </Button>
                    </Grid>
                  </Card>
                </Grid>
              <Grid
                container
                spacing={1}
              >
                <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
                >
                  <Card>
                    <CdrsTable headCells={headCells} rows={rows} loading={loading}/>
                  </Card>
                </Grid>
               </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cards_simple: state.app_reducer.cards_simple,
    cdrs: state.app_reducer.cdrs
    });

export default withStyles(useStyles)(connect( mapStateToProps,
    { getSimpleCards, getCDRs, loadingFn, clearFn })(Cdrs));
