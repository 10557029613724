import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import ListItemText from "@material-ui/core/ListItemText";

import { connect } from 'react-redux';
import { loadingFn, clearFn, setAutoRenewCharge } from '../../actions/appActions';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
});

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class RenewCharge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      monthlyFee: 0.00,
      period: 1,
      auto_renew: true
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.did_countries.length !== 0 && nextProps.rowselected.length !== 0 ) {
      let did_country = nextProps.did_countries.filter(d => d.id.toFixed() === nextProps.rowselected.country_id);
      this.setState({
        monthlyFee: parseFloat(did_country.map(d => (d.monthly_fee)))
      });
    }
    if (nextProps.rowselected.length !== 0 ) {
      this.setState({
        period: parseFloat(nextProps.rowselected.months)
      });
    }
  }

  handleSave = async () => {
    this.setState({
      loading: true
    });
    this.props.clearFn();
    this.props.loadingFn(true);
     await this.props.setAutoRenewCharge(this.props.rowselected.card_id, this.props.rowselected.auto_renew, this.props.rowselected.did, this.state.period, this.props.rowselected.country_id);
    this.setState({
      loading: false
    });
    this.props.handleClose();
  };

  onChangePeriod = async (e) => {
    this.setState({ period: e.target.value });
  };

  onAutoRenew = (e) => {
    this.setState({auto_renew: e.target.checked})
  };

  render() {
    const { rowselected, handleClose, open } = this.props;
    const { loading,  monthlyFee,  period, auto_renew} = this.state;

    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ backgroundColor: '#3f51b5' }}>
            <b style={{ color: 'white' }}>Auto Renew: {rowselected.did}</b>
          </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  <b>Warning !!!</b>
                </Typography>
                <Typography gutterBottom>
                  Your number: <b>{rowselected.did}</b> has been <b>expired</b><br/>
                </Typography>
                <Typography gutterBottom style={{marginTop: '10px'}}>
                  <b>SELECT TIME PERIOD YOU WANT TO RENEW:</b>
                </Typography>
                <div style={{marginTop: '10px'}}>
                  <FormControl
                      variant="outlined"
                  >
                    <InputLabel
                      id="period"
                    >
                      Period
                    </InputLabel>
                    <Select
                    style={{width: '200px'}}
                      disabled={loading}
                      labelId="period"
                      id="period"
                      label="Period"
                      value={period}
                      onChange={this.onChangePeriod}>
                      <MenuItem value={1}>1 Month</MenuItem>
                      <MenuItem value={3}>3 Months </MenuItem>
                      <MenuItem value={6}>6 Months</MenuItem>
                      <MenuItem value={12}>12 Months</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    style={{marginLeft: '10px'}}
                    control={
                      <Switch
                        disabled={loading}
                        checked={auto_renew}
                        onChange={this.onAutoRenew}
                        color="primary"
                        name="enabled"
                        inputProps={{ 'aria-label': 'checkbox enabled' }}
                      />
                    }
                    label="Auto Renew"
                  />
                </div>
                <div  style={{marginTop: '10px'}}>
                <Typography gutterBottom style={{marginTop: '10px'}}>
                    <b>ORDER REVIEW:</b>
                </Typography>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <ListItemText
                                primary={'Monthly FEE x '+period}
                            />
                          </TableCell>
                          <TableCell align="right">{parseFloat(monthlyFee * period).toFixed(2)}</TableCell>
                        </TableRow>
                      <TableRow>
                        <TableCell align="right"><Typography variant="h4"><b>Total</b></Typography></TableCell>
                        <TableCell align="right"><Typography variant="h4">
                            <b>$ {parseFloat((monthlyFee * period)).toFixed(2)}</b>
                          </Typography></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                </div>
              </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              disabled={loading ? true : false}>
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={this.handleSave}
              color="secondary"
              disabled={loading ? true : false}>
              BUY
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  did_countries: state.app_reducer.did_countries
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { loadingFn, clearFn, setAutoRenewCharge })(RenewCharge)
);
