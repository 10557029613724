import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Link,
  Checkbox,
  Typography
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';
import { loadingFn, clearFn, login_admin } from '../../actions/appActions';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'reqiored' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'reqiored' },
    length: {
      maximum: 128
    }
  }
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: 'url(/images/360roaming_login.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
    marginTop: '30%'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  }
}));

const SignIn = (props) => {
  const {
    history,
    login_admin,
    error_axios,
    success_axios,
    loading,
    loadingFn,
    clearFn
  } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    response_error: false,
    response_success: false
  });

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFormState((formState) => ({
      ...formState,
      response_error: false
    }));
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFormState((formState) => ({
      ...formState,
      response_success: false
    }));
  };

  useEffect(() => {
    async function clearRedux() {
      await clearFn();
      await handleCloseError();
      await handleCloseSuccess();
    }
    clearRedux();
  }, [clearFn]);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  useEffect(() => {
    if (Object.entries(error_axios).length !== 0) {
      setFormState((formState) => ({
        ...formState,
        response_error: true,
        response_success: false
      }));
    }
    if (Object.entries(success_axios).length !== 0) {
      setFormState((formState) => ({
        ...formState,
        response_error: false,
        response_success: true
      }));
    }
  }, [error_axios, success_axios]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    clearFn();
    loadingFn(true);
    login_admin(formState.values);
    history.push('/admin/users');
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                International Data Roaming
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Connecting People at Anytime & Anywhere.
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  Admin Login
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? 'wrong Email' : null}
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                  disabled={loading ? true : false}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                  disabled={loading ? true : false}
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.policy || false}
                    className={classes.policyCheckbox}
                    color="primary"
                    name="policy"
                    onChange={handleChange}
                    style={{ flex: '0' }}
                  />
                  <Typography
                    className={classes.policyText}
                    style={{ flex: '1' }}
                    color="textSecondary"
                    variant="body1">
                    Remember Me{' '}
                  </Typography>
                  <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body1">
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="/admin/forgotpass"
                      underline="always"
                      variant="h6">
                      Forgot password?
                    </Link>
                  </Typography>
                </div>
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid || loading ? true : false}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  {loading ? <CircularProgress size={24} /> : 'Login'}
                </Button>
              </form>
              <Snackbar
                open={formState.response_error ? true : false}
                autoHideDuration={6000}
                onClose={handleCloseError}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleCloseError} severity="error">
                  <Typography style={{ color: 'white' }} variant="h4">
                    {error_axios}
                  </Typography>
                </Alert>
              </Snackbar>
              <Snackbar
                open={formState.response_success ? true : false}
                autoHideDuration={6000}
                onClose={handleCloseSuccess}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleCloseSuccess} severity="success">
                  <Typography style={{ color: 'white' }} variant="h4">
                    {success_axios}
                  </Typography>
                </Alert>
              </Snackbar>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  error_axios: state.app_reducer.error_axios,
  success_axios: state.app_reducer.success_axios,
  loading: state.app_reducer.loading
});

export default connect(mapStateToProps, {
  login_admin,
  loadingFn,
  clearFn
})(withRouter(SignIn));
