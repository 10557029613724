import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import { PayPalButton } from 'react-paypal-button-v2';

import { connect } from 'react-redux';
import { loadingFn, clearFn, saveAutoChargeUser } from '../../../../actions/appActions';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class AutoChargeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleSave = async (details) => {
    this.setState({
      loading: true
    });
    console.log(details);
    await this.props.saveAutoChargeUser(details);

    this.setState({
      loading: false
    });
    this.props.handleClose();
  };

  render() {
    const { handleClose, open, amount } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ backgroundColor: '#3f51b5' }}>
            <b style={{ color: 'white' }}>Confirmation</b>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <b>SUBSCRIBE with Paypal.</b>
            </Typography>
            <Typography gutterBottom>Please confirm or cancel.</Typography>
            <img
              src="/images/satisfaction-guarantee.png"
              alt="Paypal secure"
              height="300px"
            />
            <Alert severity="success">
              {' '}
              <Typography variant="h5" style={{ color: 'white' }}>
              Subscription Plan: <b>{amount} USD / Month</b>{' '}
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions>
            <PayPalButton
            disabled={loading ? true : false}
              options={{ vault: true }}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: amount === '10' ? 'P-9WT42355G6110882FL6EZPUY'
                  : amount === '20' ? 'P-0X1778284P099090SL6EZP7I'
                  : amount === '50' ? 'P-8SX44015FY9581126L6EZQGI'
                  : amount === '100' ? 'P-0G010646KX5235813L6EZQPY'
                  : amount === '200' ? 'P-4X039182HP644693RL6EZQWQ'
                  : amount === '500' ? 'P-297933550T6953531L6EZQ7A'
                  :  'P-6E0679279K228743TL6EZRGA'
                });
              }}
              onApprove={(data, actions) => {
                // Capture the funds from the transaction
                return actions.subscription.get().then((details) => {
                  this.handleSave(details);
                });
              }}
            />
                        <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              disabled={loading ? true : false}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error_axios: state.app_reducer.error_axios
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { loadingFn, clearFn, saveAutoChargeUser })(AutoChargeDialog)
);
