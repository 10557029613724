import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { TextField } from '@material-ui/core';


import { connect } from "react-redux";
import { setVoicemail, loadingFn, clearFn } from "../../actions/appActions";

const useStyles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
    },
  });

  const DialogTitle = withStyles(useStyles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

class VoiceMail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            timeout: '30',
            voicemail: false,
            errors: {},
        }
    }


    handleSave = async (e) => {
      e.preventDefault();
      const { timeout, errors, voicemail } = this.state;
      if (timeout === "" || timeout < 30 || timeout > 50 || !timeout.match(/^[0-9\b]+$/)) {
        this.setState({
            errors: { ...errors, timeout: true  }
        });
        return;
      }
        this.setState({
          loading: true
        });
        this.props.clearFn();
        this.props.loadingFn(true);
        await this.props.setVoicemail(this.props.rowselected.card_nr, timeout, voicemail ? 'on' : 'off' );
        this.setState({
            loading: false
        });
        this.props.handleClose();
    }

    handleChange = e => {
      e.persist();
      const { errors } = this.state;
      this.setState(
        ((e.target.id === 'timeout' && !e.target.value.match(/^[0-9\b]+$/))||
        (e.target.id === 'timeout' && e.target.value < 30) ||
        (e.target.id === 'timeout' && e.target.value > 50)) ?
        {
          [e.target.id]: e.target.value,
          errors: {
              ...errors,
              [e.target.id]: true
          }
        }
        : (e.target.name === 'voicemail' ) ? {
          [e.target.name]: e.target.checked
        }
        : {
          [e.target.id]: e.target.value,
          errors: {
            ...errors,
            [e.target.id]: false
          }
        }
      );
    };

  render () {
    const { rowselected, handleClose, open } = this.props;
    const { loading, timeout, errors, voicemail } = this.state;
    return (
        <div>
           <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{backgroundColor: "#3f51b5"}}>
           <b style={{color: "white"}}>Voice Mail card: {rowselected.card_nr}</b>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <b>Warning !!!</b>
          </Typography>
          <Typography gutterBottom>
           You are trying to set voice mail to your card - {rowselected.card_nr}
          </Typography>
          <Switch
            checked={voicemail}
            onChange={this.handleChange}
            color="primary"
            name="voicemail"
            inputProps={{ 'aria-label': 'voicemail' }}
          />
          <TextField
            fullWidth
            label="Time out in sec."
            name="timeout"
            id="timeout"
            type="number"
            onChange={this.handleChange}
            onFocus={this.handleChange}
            onBlur={this.handleChange}
            style={{ marginTop: '1rem' }}
            value={timeout}
            InputProps={{ inputProps: { min: 30, max: 60 } }}
            variant="outlined"
            error={errors.timeout ? true : false  }
            helperText={
              errors.timeout ? 'Wrong value please use beetwen 30 and 50' : null
            }
          />
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" disabled={loading ? true : false}>
            Cancel
          </Button>
          <Button autoFocus onClick={this.handleSave} color="secondary" disabled={loading ? true : false}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
        </div>
      );
  }
}

const mapStateToProps = state => ({
    error_axios: state.app_reducer.error_axios
  });

export default withStyles(useStyles)(connect(
    mapStateToProps,
{ loadingFn, clearFn, setVoicemail }
)(VoiceMail));
