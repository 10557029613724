import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';

import { connect } from 'react-redux';
import { registerCard, loadingFn, clearFn } from '../../actions/appActions';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
});

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class RegisterCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      card: '',
      puk: '',
      errors: {}
    };
  }

  handleSave = async (e) => {
    e.preventDefault();
    const { card, errors, puk } = this.state;
    if (card === '' || card.length !== 12 || !card.match(/^[0-9\b]+$/)) {
      this.setState({
        errors: { ...errors, card: true }
      });
      return;
    }
    if (puk === '' || puk.length !== 8 || !puk.match(/^[0-9\b]+$/)) {
      this.setState({
        errors: { ...errors, card: true }
      });
      return;
    }
    this.setState({
      loading: true
    });
    this.props.clearFn();
    this.props.loadingFn(true);
    await this.props.registerCard(card, puk);
    this.setState({
      loading: false
    });
    this.props.handleClose();
  };

  handleChange = (e) => {
    e.persist();
    const { errors } = this.state;
    this.setState(
      (e.target.id === 'card' && !e.target.value.match(/^[0-9\b]+$/)) ||
        (e.target.id === 'card' && e.target.value.length !== 12)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              ...errors,
              [e.target.id]: true
            }
          }
        : (e.target.id === 'puk' && !e.target.value.match(/^[0-9\b]+$/)) ||
          (e.target.id === 'puk' && e.target.value.length !== 8)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              ...errors,
              [e.target.id]: true
            }
          }
        : {
            [e.target.id]: e.target.value,
            errors: {
              ...errors,
              [e.target.id]: false
            }
          }
    );
  };

  render() {
    const { handleClose, open } = this.props;
    const { loading, card, errors, puk } = this.state;
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ backgroundColor: '#3f51b5' }}>
            <b style={{ color: 'white' }}>REGISTER YOUR CARD</b>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <b>Please Enter your Card information.</b>
            </Typography>
            <Typography gutterBottom>
              To register your Card you need to prepare the following
              information.
            </Typography>

            <TextField
              fullWidth
              label="CARD NUMBER"
              name="card"
              id="card"
              type="text"
              onChange={this.handleChange}
              onFocus={this.handleChange}
              onBlur={this.handleChange}
              style={{ marginTop: '1rem' }}
              value={card}
              variant="outlined"
              error={errors.card ? true : false}
              helperText={
                errors.card ? 'Wrong value please use 12 digits' : null
              }
            />
            <TextField
              fullWidth
              label="PUK NUMBER"
              name="puk"
              id="puk"
              type="text"
              onChange={this.handleChange}
              onFocus={this.handleChange}
              onBlur={this.handleChange}
              style={{ marginTop: '1rem' }}
              value={puk}
              variant="outlined"
              error={errors.puk ? true : false}
              helperText={errors.puk ? 'Wrong value please use 8 digits' : null}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              disabled={loading ? true : false}>
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={this.handleSave}
              color="secondary"
              disabled={loading ? true : false}>
              Register
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error_axios: state.app_reducer.error_axios
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { loadingFn, clearFn, registerCard })(RegisterCard)
);
