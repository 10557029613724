import React, { useEffect } from 'react';
//import { forwardRef } from 'react';
//import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { lighten, makeStyles, useTheme, fade } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
//import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';

import InputBase from '@material-ui/core/InputBase';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Card } from '@material-ui/core';

//import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
//import CreditCardIcon from '@material-ui/icons/CreditCard';

import { green } from '@material-ui/core/colors';
//import Avatar from '@material-ui/core/Avatar';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    } else {
      return a[1] - b[1];
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { headCells, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.id !== 'actions' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: '48px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  },
  search: {
    backgroundColor: 'aliceblue',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'black'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  }
}));

//const CustomRouterLink = forwardRef((props, ref) => (
//  <div ref={ref}>
//    <RouterLink {...props} />
//  </div>
//));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { handleSearch, searchval } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        color="inherit"
        variant="subtitle1"></Typography>
      {/*
      {numSelected > 0 ? (
        <React.Fragment>
        <Tooltip title={"Check CDRs"}>
            <IconButton aria-label="Check CDRs" component={CustomRouterLink} to={"/cdrs/"+selectedRow.id}>
            <CalendarTodayIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title={"Virtual Number"}>
            <IconButton aria-label="Virtual Number"  component={CustomRouterLink} to={"/dids/"+selectedRow.id}>
            <ContactPhoneIcon  />
            </IconButton>
        </Tooltip>
        {selectedRow.did ?  <Tooltip title={"SET CLI"}>
            <IconButton aria-label="SET CLI" onClick={handleSetCliOpen}>
            <AssignmentIndIcon  />
            </IconButton>
        </Tooltip> : null}
        <Tooltip title={"Activate Voicemail"}>
            <IconButton aria-label="Virtual Number" onClick={handleVoicemailOpen}>
            <VoicemailIcon  />
            </IconButton>
        </Tooltip>
        <Tooltip title={"Boundles"}>
            <IconButton  aria-label="Boundles" component={CustomRouterLink} to={"/boundles/"+selectedRow.id}>
            <PhoneIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title={"TOP UP"}>
            <IconButton  aria-label="TOP UP" component={CustomRouterLink} to={"/topup/"+selectedRow.id}>
            <CreditCardIcon />
            </IconButton>
        </Tooltip>
      </React.Fragment>
  */}
      <React.Fragment>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search …"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            id="id_search"
            value={searchval}
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleSearch}
          />
        </div>
      </React.Fragment>
    </Toolbar>
  );
};

const useStyles1 = makeStyles(() => ({
  root: {
    flexShrink: 0,
    margin: 0
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        style={{paddingRight: '0px'}}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="pierwsza">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        style={{paddingRight: '0px'}}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        style={{paddingRight: '0px'}}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="następna">
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        style={{paddingRight: '0px'}}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="ostatnia">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: '100%'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
    width: '70px',
    height: '20px',
    fontSize: '14px'
  }
}));

function CardsTable(props) {
  const { rows, headCells } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('card_nr');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = React.useState([]);
  const [searchval, setSearchval] = React.useState({ search: '' });

  const [loading, setLoading] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    event.persist();
    setSearchval((searchval) => ({ ...searchval, search: event.target.value }));
  };

  useEffect(() => {
    if (rows.length === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (searchval.search === '') {
      setSearch(rows);
    } else {
      let rest = [];
      rows.forEach(function (row) {
        return Object.keys(row).some(function (key) {
          if (row[key] !== null) {
            if (
              row[key]
                .toString()
                .toLowerCase()
                .includes(searchval.search.toLowerCase())
            ) {
              return rest.push(row);
            }
          }
          return false;
        });
      });
      if (rest.length === 0) {
        setSearch([]);
      } else {
        setSearch(rest);
      }
    }
  }, [rows, searchval]);

  //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Card>
      <div className={classes.root}>
        <EnhancedTableToolbar
          handleSearch={handleSearch}
          searchval={searchval.search}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            style={{ borderCollapse: 'separate' }}>
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {search.length !== 0 ? (
                stableSort(search, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        <TableCell align="center" id={labelId} scope="row">
                          {row.email}
                        </TableCell>
                        <TableCell align="center">{row.card_nr}</TableCell>
                        <TableCell align="center">{row.puk}</TableCell>
                        <TableCell align="center">{row.order_id}</TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow role="checkbox">
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell
                    style={{
                      verticalAlign: 'middle',
                      left: '40%',
                      position: 'sticky'
                    }}>
                    {loading ? <CircularProgress size={40} /> : 'No data'}
                  </TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            25,
            50,
            100,
            { label: 'All', value: rows.length }
          ]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage=""
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            ''.concat(
              `${from}`,
              ' to ',
              `${to === -1 ? count : to}`,
              ' from ',
              `${count !== -1 ? count : 'more then '.concat(`${to}`)}`
            )
          }
        />
      </div>
    </Card>
  );
}
export default CardsTable;
