import React, { forwardRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import PaymentTable from './PaymentTable';
import { NavLink as RouterLink } from 'react-router-dom';
import PaymentDialog from './PaymentDialog';
import {
  getSimpleCards,
  getPayments,
  loadingFn,
  clearFn,
  getBalance
} from '../../actions/appActions';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Button
} from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = (theme) => ({
  root: {
    paddingTop: '32px',
    paddingRight: '2px',
    paddingLeft: '21px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2px'
    }
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Payments extends React.Component {
  constructor() {
    super();
    this.state = {
      value: null,
      startDate: this.todayStart(),
      endDate: this.todayEnd(),
      period: 1,
      top_up: 10,
      open_dialog: false,
      loading: false,
      loadingEx: false,
      openSnackBarError: false,
      openSnackBarSuccess: false
    };
  }

  handleChangeTopUp = (event) => {
    this.setState({ top_up: event.target.value });
  };

  handleChangePeriod = (event) => {
    this.setState({ period: event.target.value });
    if (event.target.value === 1) {
      this.setState({ startDate: this.todayStart() });
      this.setState({ endDate: this.todayEnd() });
    }
    if (event.target.value === 2) {
      this.setState({ startDate: this.yesterdyStart() });
      this.setState({ endDate: this.yesterdyEnd() });
    }
    if (event.target.value === 3) {
      this.setState({ startDate: this.thisWeekStart() });
      this.setState({ endDate: this.thisWeekEnd() });
    }
    if (event.target.value === 4) {
      this.setState({ startDate: this.lastWeekStart() });
      this.setState({ endDate: this.lastWeekEnd() });
    }
    if (event.target.value === 5) {
      this.setState({ startDate: this.thisMonthStart() });
      this.setState({ endDate: this.thisMonthEnd() });
    }
    if (event.target.value === 6) {
      this.setState({ startDate: this.lastMonthStart() });
      this.setState({ endDate: this.lastMonthEnd() });
    }
  };

  handleStartDateChange = (date) => {
    // cant be lower then end date
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    // cant be higher then end date
    this.setState({ endDate: date });
    // allow only 30 days period
  };

  async handleChange(id) {
    this.props.loadingFn(true);
    await this.props.getCDRs(id);
  }

  lastMonthStart() {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  }

  lastMonthEnd() {
    let now = new Date();
    now.setDate(0);
    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
    return now;
  }

  thisMonthStart() {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), 1);
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  }

  thisMonthEnd() {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
    return now;
  }

  lastWeekStart() {
    let beforeOneWeek = new Date(
      new Date().getTime() - 60 * 60 * 24 * 7 * 1000
    );
    beforeOneWeek.setHours(0);
    beforeOneWeek.setMinutes(0);
    beforeOneWeek.setSeconds(0);
    let day = beforeOneWeek.getDay();
    let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(beforeOneWeek.setDate(diffToMonday));
  }
  lastWeekEnd() {
    let beforeOneWeek = new Date(
      new Date().getTime() - 60 * 60 * 24 * 7 * 1000
    );
    beforeOneWeek.setHours(23);
    beforeOneWeek.setMinutes(59);
    beforeOneWeek.setSeconds(59);
    let day = beforeOneWeek.getDay();
    let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(beforeOneWeek.setDate(diffToMonday + 6));
  }

  thisWeekStart() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    let first = now.getDate() - now.getDay() + 1;
    return new Date(now.setDate(first));
  }
  thisWeekEnd() {
    let now = new Date();
    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
    let first = now.getDate() - now.getDay() + 1;
    let last = first + 6;
    return new Date(now.setDate(last));
  }

  todayStart() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return new Date(now);
  }
  todayEnd() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setDate(new Date().getDate() + 1);
    let nextDay = now.setDate(new Date().getDate() + 1);
    return new Date(nextDay);
  }

  yesterdyStart() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    let yesterdyStart = now - 1000 * 60 * 60 * 24 * 1;
    return new Date(yesterdyStart);
  }
  yesterdyEnd() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return new Date(now);
  }

  async UNSAFE_componentWillMount() {
    this.props.clearFn();
    this.props.loadingFn(true);
    await this.props.getSimpleCards();
    const { id, card_nr, pattern } = this.props.match.params;
    if (id !== undefined) {
      this.setState({ loading: true, value: { id: id, card_nr: card_nr } });
      await this.props.getPayments(id, this.todayStart(), this.todayEnd());
      this.setState({ loading: false });
    }
    if (pattern === 'cancel') {
      this.setState({ openSnackBarError: true });
    } else if (pattern === 'success') {
      this.props.getBalance();
      this.setState({ openSnackBarSuccess: true });
    }
  }

  handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackBarError: false });
  };
  handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackBarSuccess: false });
  };

  onTopUp = () => {
    this.setState({ open_dialog: true });
  };

  handleDialogClose = () => {
    this.setState({ open_dialog: false });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { value, startDate, endDate } = this.state;
    this.setState({ loading: true });
    this.props.clearFn();
    await this.props.getPayments(value ? value.id : null, startDate, endDate);
    this.setState({ loading: false });
  };

  onExport = async (e) => {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    const { payments } = this.props;
    if (payments.length !== 0) {
      this.setState({ loadingEx: true });
      let start =
        startDate.getFullYear() +
        '-' +
        (startDate.getMonth() + 1) +
        '-' +
        startDate.getDate();
      let end =
        endDate.getFullYear() +
        '-' +
        (endDate.getMonth() + 1) +
        '-' +
        endDate.getDate();

      let fileName = 'Payments_' + start + '_' + end;

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';

      const ws = XLSX.utils.json_to_sheet(payments);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      this.setState({ loadingEx: false });
    }
  };

  render() {
    const { payments, cards_simple, classes } = this.props;
    const {
      value,
      startDate,
      endDate,
      period,
      top_up,
      open_dialog,
      loading,
      loadingEx,
      openSnackBarError,
      openSnackBarSuccess
    } = this.state;

    const CustomRouterLink = forwardRef((props, ref) => (
      <div
        ref={ref}
        style={{ flexGrow: 1 }}
      >
        <RouterLink {...props} />
      </div>
    ));

    const rows = payments.map((row) => ({
      id: row.id,
      id_client: row.id_client,
      money: row.money,
      date: row.date,
      description: row.description,
      card: row.card_nr
    }));

    const headCells = [
      { id: 'date', numeric: false, disablePadding: true, label: 'Date' },
      { id: 'card_nr', numeric: false, disablePadding: true, label: 'Card' },
      {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: 'Description'
      },
      { id: 'money', numeric: false, disablePadding: true, label: 'Money' }
    ];
    return (
      <div className={classes.root}>
        <PaymentDialog
          open={open_dialog}
          handleClose={this.handleDialogClose}
          amount_pr={top_up}
        />
        <Typography
          variant="h4"
          style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          Payments
        </Typography>
        <Grid
          item
          lg={4}
          xl={3}
          md={6}
          xs={12}
          style={{ marginBottom: '10px' }}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  TOP UP
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Please select the amount you want to top up your account.
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                image="/images/secured-payment.png"
                title="Paypal"
                alt="Paypal"
                style={{ padding: 10, width: '300px' }}
              />
            </CardActionArea>
            <FormControl
              variant="outlined"
              style={{ padding: '15px', width: '100%' }}>
              <InputLabel
                id="period"
                style={{ paddingTop: '21px', paddingLeft: '15px' }}>
                Amount
              </InputLabel>
              <Select
                disabled={loading || loadingEx ? true : false}
                labelId="top_up"
                id="top_up"
                label="Period"
                value={top_up}
                onChange={this.handleChangeTopUp}>
                <MenuItem value={10}>10 USD</MenuItem>
                <MenuItem value={20}>20 USD</MenuItem>
                <MenuItem value={50}>50 USD</MenuItem>
                <MenuItem value={100}>100 USD</MenuItem>
                <MenuItem value={200}>200 USD</MenuItem>
                <MenuItem value={500}>500 USD</MenuItem>
                <MenuItem value={1000}>1000 USD</MenuItem>
              </Select>
            </FormControl>
            <CardActions style={{ float: 'right' }}>
            <Button
                size="small"
                color="secondary"
                variant="contained"
                component={CustomRouterLink}
                to="/settings">
                AUTO CHARGE
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  this.setState({ open_dialog: true });
                }}>
                TOP UP
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item lg={12} md={12} xl={8} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                Payments History / {value !== null ? value.card_nr : 'All'}
              </Typography>
            </CardContent>
            <Grid container justify="space-around">
              <Autocomplete
                disabled={loading || loadingEx ? true : false}
                id="cards"
                onChange={(event, newValue) => {
                  this.setState({ value: newValue });
                }}
                options={cards_simple}
                value={value}
                getOptionSelected={(option, value) =>
                  option.card_nr === value.card_nr
                }
                getOptionLabel={(option) => option.card_nr}
                style={{ width: 200, paddingTop: '15px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Card"
                    variant="outlined"
                  />
                )}
              />
              <FormControl
                variant="outlined"
                style={{ paddingTop: '15px', width: '200px' }}>
                <InputLabel id="period" style={{ paddingTop: '17px' }}>
                  Period
                </InputLabel>
                <Select
                  disabled={loading || loadingEx ? true : false}
                  labelId="period"
                  id="period"
                  label="Period"
                  value={period}
                  onChange={this.handleChangePeriod}>
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>Yesterday</MenuItem>
                  <MenuItem value={3}>This Week</MenuItem>
                  <MenuItem value={4}>Last Week</MenuItem>
                  <MenuItem value={5}>This Month</MenuItem>
                  <MenuItem value={6}>Last Month</MenuItem>
                  <MenuItem value={7}>Custom</MenuItem>
                </Select>
              </FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disabled={
                    (period === 7 && !loading) || (period === 7 && !loadingEx)
                      ? false
                      : true
                  }
                  inputVariant="outlined"
                  style={{ width: 200 }}
                  autoOk
                  value={startDate}
                  onChange={this.handleStartDateChange}
                  variant="inline"
                  format="yyyy-MM-dd HH:mm"
                  margin="normal"
                  id="start-date"
                  keyboardbuttonprops={{
                    'aria-label': 'start-date'
                  }}
                  label={'Start Date'}
                />
                <DatePicker
                  disabled={
                    (period === 7 && !loading) || (period === 7 && !loadingEx)
                      ? false
                      : true
                  }
                  inputVariant="outlined"
                  style={{ width: 200 }}
                  autoOk
                  value={endDate}
                  onChange={this.handleEndDateChange}
                  variant="inline"
                  format="yyyy-MM-dd HH:mm"
                  margin="normal"
                  id="end-date"
                  keyboardbuttonprops={{
                    'aria-label': 'end-date'
                  }}
                  label={'End Date'}
                />
              </MuiPickersUtilsProvider>
              <Button
                disabled={loading || loadingEx ? true : false}
                style={{ margin: '14px' }}
                color="primary"
                variant="contained"
                onClick={this.onSubmit}>
                Submit
              </Button>
              <Button
                disabled={
                  loading || loadingEx || payments.length === 0 ? true : false
                }
                style={{ margin: '14px' }}
                color="secondary"
                variant="contained"
                onClick={this.onExport}>
                Export
              </Button>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={12} xl={8} md={12} xs={12}>
          <Card>
            <PaymentTable headCells={headCells} rows={rows} loading={loading} />
          </Card>
        </Grid>

        <Snackbar
          open={openSnackBarError}
          autoHideDuration={6000}
          onClose={this.handleCloseError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert onClose={this.handleCloseError} severity="error">
            <Typography style={{ color: 'white' }} variant="h4">
              Your Payment has been Canceled.
            </Typography>
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackBarSuccess}
          autoHideDuration={6000}
          onClose={this.handleCloseSuccess}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={this.handleCloseSuccess} severity="success">
            <Typography style={{ color: 'white' }} variant="h4">
              Payment Success.
            </Typography>
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cards_simple: state.app_reducer.cards_simple,
  payments: state.app_reducer.payments
});

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    getSimpleCards,
    getPayments,
    loadingFn,
    clearFn,
    getBalance
  })(Payments)
);
