import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SimCardIcon from '@material-ui/icons/SimCard';
import PhoneIcon from '@material-ui/icons/Phone';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 180,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, history, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Cards',
      href: '/cards',
      icon: <SimCardIcon />
    },
    {
      title: 'CDRs',
      href: '/cdrs',
      icon: <CalendarTodayIcon />
    },
    {
      title: 'Orders',
      href: '/orders',
      icon: <ShoppingCartIcon />
    },
    {
      title: 'Local Numbers',
      href: '/dids',
      icon: <ContactPhoneIcon />
    },
    {
      title: 'Bundles',
      href: '/bundles',
      icon: <PhoneIcon />
    },
    {
      title: 'Payments',
      href: '/payments',
      icon: <AccountBalanceIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <MoreVertIcon />
    },
    {
      title: 'Logout',
      href: '/logout',
      icon: <InputIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav history={history} className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
