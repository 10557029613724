import {
  ERROR_AXIOS,
  AUTH_APP,
  AUTH_APP_LOGOUT,
  APP_LOADING,
  SM_LOADING,
  OD_LOADING,
  CLEAR_RESPONSE,
  SIGNUP_APP,
  CHECK_SIGNUP_TOKEN,
  GET_CARDS,
  GET_SIMPLE_CARDS,
  GET_CDRS,
  SET_VOICEMAIL,
  GET_BALANCE,
  GET_PAYMENTS,
  SET_TOPUP,
  SET_AUTOCHARGE,
  REGISTER_CARD,
  FORGOT_PASS,
  RESET_PASS,
  GET_AUTOCHARGE_USER,
  CANCEL_AUTOCHARGE_USER,
  SAVE_AUTOCHARGE_USER,
  GET_DID_COUNTRIES,
  GET_AREAS,
  BUY_DID,
  GET_DIDS,
  SET_CLI,
  SET_AUTORENEW_DID,
  SET_AUTORENEWCHARGE_DID,
  CANCEL_DID
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  loading: false,
  loading_sm: false,
  loading_od: false,
  error_axios: '',
  success_axios: '',
  cdrs: [],
  cards: [],
  cards_simple: [],
  total_cards: 0,
  balance: '0.0000',
  payments: [],
  user_autocharge: [],
  did_countries: [],
  did_areas: [],
  dids: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SM_LOADING:
      return {
        ...state,
        loading_sm: action.payload
      };
    case OD_LOADING:
      return {
        ...state,
        loading_od: action.payload
      };
    case  GET_DIDS:
      return {
        ...state,
        dids: action.payload,
        loading: false
      };
    case  GET_AREAS:
        return {
          ...state,
          did_areas: action.payload,
          loading: false
        };
    case GET_DID_COUNTRIES:
      return {
        ...state,
        did_countries: action.payload,
        loading: false
      };
    case CANCEL_DID:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case SET_CLI:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
      case SET_AUTORENEWCHARGE_DID:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case SET_AUTORENEW_DID:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case BUY_DID:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case GET_AUTOCHARGE_USER:
      return {
        ...state,
        user_autocharge: action.payload.data,
        loading: false
      };
    case SAVE_AUTOCHARGE_USER:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case  CANCEL_AUTOCHARGE_USER:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case SET_AUTOCHARGE:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case SET_TOPUP:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload.data,
        loading: false
      };
    case GET_BALANCE:
      return {
        ...state,
        balance: action.payload.data
      };
    case GET_CDRS:
      return {
        ...state,
        cdrs: action.payload.data,
        loading: false
      };
    case GET_SIMPLE_CARDS:
      return {
        ...state,
        cards_simple: action.payload.data,
        loading: false
      };
    case GET_CARDS:
      return {
        ...state,
        cards: action.payload.data[0],
        total_cards: action.payload.data[1],
        loading: false
      };
    case RESET_PASS:
      return {
        ...state,
        success_axios: action.payload.data,
        loading: false
      };
    case FORGOT_PASS:
      return {
        ...state,
        success_axios: action.payload.data,
        loading: false
      };
    case REGISTER_CARD:
      return {
        ...state,
        success_axios: action.payload.data,
        loading: false
      };
    case SET_VOICEMAIL:
      return {
        ...state,
        success_axios: action.payload,
        loading: false
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        error_axios: '',
        success_axios: ''
      };
    case APP_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case CHECK_SIGNUP_TOKEN:
      return {
        ...state,
        loading: false,
        success_axios: action.payload,
        error_axios: ''
      };
    case SIGNUP_APP:
      return {
        ...state,
        loading: false,
        success_axios: action.payload,
        error_axios: ''
      };
    case AUTH_APP:
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: action.payload,
        loading: false,
        error_axios: ''
      };
    case AUTH_APP_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isAdmin: false,
        loading: false,
        error_axios: '',
        success_axios: ''
      };
    case ERROR_AXIOS:
      return {
        ...state,
        error_axios: action.payload,
        loading: false,
        loading_sm: false,
        loading_od: false,
        success_axios: ''
      };
    default:
      return state;
  }
}
