import React, { Component } from 'react';
import AuthRoutes from '../AuthRoutes';
import AuthAdminRoutes from '../AuthAdminRoutes';
import Routes from '../Routes';
import RoutesAdmin from '../RoutesAdmin';

import { connect } from 'react-redux';
import { checkAuth } from '../actions/appActions';

class AuthComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async UNSAFE_componentWillMount() {
    const token = localStorage.getItem('jwtToken');
    const id = localStorage.getItem('jwtTokenid');
    if (token && !this.props.isAuthenticated) {
      await this.props.checkAuth(token, id);
      this.setState({ lodaing: false });
    }
  }

  render() {
    return this.props.isAuthenticated &&
      localStorage.getItem('jwtToken') &&
      !this.props.isAdmin ? (
      <AuthRoutes />
    ) : this.props.isAuthenticated &&
      localStorage.getItem('jwtToken') &&
      this.props.isAdmin ? (
      <AuthAdminRoutes />
    ) : this.state.loading &&
      !this.props.isAuthenticated &&
      !localStorage.getItem('jwtToken') &&
      this.props.history.location.pathname.substring(0, 6) === '/admin' ? (
      <RoutesAdmin />
    ) : this.state.loading &&
      !this.props.isAuthenticated &&
      !localStorage.getItem('jwtToken') &&
      this.props.history.location.pathname.substring(0, 1) === '/' ? (
      <Routes />
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.app_reducer.isAuthenticated,
  isAdmin: state.app_reducer.isAdmin
});

export default connect(mapStateToProps, { checkAuth })(AuthComponent);
