import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';


import { connect } from "react-redux";
import { setTopUp, loadingFn, clearFn } from "../../actions/appActions";

const useStyles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "white",
    },
  });

  const DialogTitle = withStyles(useStyles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

class TopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            amount:  parseFloat(1.00).toFixed(2),
            errors: {},
        }
    }


    handleSave = async (e) => {
      e.preventDefault();
      const { amount, errors } = this.state;
      const { rowselected } = this.props;
      if (amount === "" || parseFloat(amount) < parseFloat(0.01) || parseFloat(amount) > parseFloat(this.props.balance)) {
        this.setState({
            errors: { ...errors, amount: true  }
        });
        return;
      }
        this.props.clearFn();
        this.setState({
          loading: true
        });
        this.props.loadingFn(true);
        //top up
        await this.props.setTopUp(rowselected.id, amount, rowselected.card_nr);
        this.setState({
            loading: false
        });
        this.props.handleClose();
    }

    handleChange = e => {
      e.persist();
      const { errors } = this.state;
      this.setState(
        (e.target.value === '' ) ||
        (parseFloat(e.target.value) < parseFloat(0.01)) ||
        (parseFloat(e.target.value) > parseFloat(this.props.balance)) ?
        {
          [e.target.id]: e.target.value,
          errors: {
              ...errors,
              [e.target.id]: true
          }
        }
        : {
          [e.target.id]: e.target.value,
          errors: {
            ...errors,
            [e.target.id]: false
          }
        }
      );
    };

  render () {
    const { rowselected, handleClose, open, balance } = this.props;
    const { loading, amount, errors } = this.state;
    return (
        <div>
           <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{backgroundColor: "#3f51b5"}}>
           <b style={{color: "white"}}>Top Up card {rowselected.card_nr}</b>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <b>Warning !!!</b>
          </Typography>
          <Typography gutterBottom>
           You are trying Top Up your card - {rowselected.card_nr}
          </Typography>
          <Typography gutterBottom>
           Please select amount.
          </Typography>
          <TextField
            fullWidth
            label="Amount"
            name="amount"
            id="amount"
            type="number"
            onChange={this.handleChange}
            onFocus={this.handleChange}
            onBlur={this.handleChange}
            style={{ marginTop: '1rem' }}
            value={amount}
            InputProps={{ inputProps: { min: 0.01, max: balance, step: ".01"} }}
            variant="outlined"
            error={errors.amount ? true : false  }
            helperText={
              errors.amount ? 'Wrong value please use beetwen 0.01 and '+balance : null
            }
          />
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" disabled={loading ? true : false}>
            Cancel
          </Button>
          <Button autoFocus onClick={this.handleSave} color="secondary" disabled={loading ? true : false}>
            Top Up
          </Button>
        </DialogActions>
      </Dialog>
        </div>
      );
  }
}

const mapStateToProps = state => ({
    error_axios: state.app_reducer.error_axios
  });

export default withStyles(useStyles)(connect(
    mapStateToProps,
{ loadingFn, clearFn, setTopUp }
)(TopUp));
