import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import PaymentTable from './PaymentTable';
import {
  getSimpleCards,
  getSimpleUsers,
  getPayments,
  loadingFn,
  clearFn
} from '../../actions/adminActions';
import { Card, CardContent, Grid, Button } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const useStyles = (theme) => ({
  root: {
    paddingTop: '32px',
    paddingRight: '2px',
    paddingLeft: '21px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2px'
    }
  }
});

class Payments extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      card: null,
      startDate: this.todayStart(),
      endDate: this.todayEnd(),
      period: 1,
      loading: false,
      loadingEx: false
    };
  }

  async UNSAFE_componentWillMount() {
    this.props.clearFn();
    this.props.loadingFn(true);
    await this.props.getSimpleCards();
    await this.props.getSimpleUsers();
    const { id } = this.props.match.params;
    const one = this.props.users_simple.filter(
      (user) => user.id === Number(id)
    );
    if (id !== undefined && one.length > 0) {
      this.setState({ loading: true, user: one[0] });
      await this.props.getPayments(
        id,
        null,
        this.todayStart(),
        this.todayEnd()
      );
      this.setState({ loading: false });
    }
  }

  handleChangePeriod = (event) => {
    this.setState({ period: event.target.value });
    if (event.target.value === 1) {
      this.setState({ startDate: this.todayStart() });
      this.setState({ endDate: this.todayEnd() });
    }
    if (event.target.value === 2) {
      this.setState({ startDate: this.yesterdyStart() });
      this.setState({ endDate: this.yesterdyEnd() });
    }
    if (event.target.value === 3) {
      this.setState({ startDate: this.thisWeekStart() });
      this.setState({ endDate: this.thisWeekEnd() });
    }
    if (event.target.value === 4) {
      this.setState({ startDate: this.lastWeekStart() });
      this.setState({ endDate: this.lastWeekEnd() });
    }
    if (event.target.value === 5) {
      this.setState({ startDate: this.thisMonthStart() });
      this.setState({ endDate: this.thisMonthEnd() });
    }
    if (event.target.value === 6) {
      this.setState({ startDate: this.lastMonthStart() });
      this.setState({ endDate: this.lastMonthEnd() });
    }
  };

  handleStartDateChange = (date) => {
    // cant be lower then end date
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    // cant be higher then end date
    this.setState({ endDate: date });
    // allow only 30 days period
  };

  lastMonthStart() {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  }

  lastMonthEnd() {
    let now = new Date();
    now.setDate(0);
    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
    return now;
  }

  thisMonthStart() {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), 1);
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  }

  thisMonthEnd() {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
    return now;
  }

  lastWeekStart() {
    let beforeOneWeek = new Date(
      new Date().getTime() - 60 * 60 * 24 * 7 * 1000
    );
    beforeOneWeek.setHours(0);
    beforeOneWeek.setMinutes(0);
    beforeOneWeek.setSeconds(0);
    let day = beforeOneWeek.getDay();
    let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(beforeOneWeek.setDate(diffToMonday));
  }
  lastWeekEnd() {
    let beforeOneWeek = new Date(
      new Date().getTime() - 60 * 60 * 24 * 7 * 1000
    );
    beforeOneWeek.setHours(23);
    beforeOneWeek.setMinutes(59);
    beforeOneWeek.setSeconds(59);
    let day = beforeOneWeek.getDay();
    let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(beforeOneWeek.setDate(diffToMonday + 6));
  }

  thisWeekStart() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    let first = now.getDate() - now.getDay() + 1;
    return new Date(now.setDate(first));
  }
  thisWeekEnd() {
    let now = new Date();
    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
    let first = now.getDate() - now.getDay() + 1;
    let last = first + 6;
    return new Date(now.setDate(last));
  }

  todayStart() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return new Date(now);
  }
  todayEnd() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setDate(new Date().getDate() + 1);
    let nextDay = now.setDate(new Date().getDate() + 1);
    return new Date(nextDay);
  }

  yesterdyStart() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    let yesterdyStart = now - 1000 * 60 * 60 * 24 * 1;
    return new Date(yesterdyStart);
  }
  yesterdyEnd() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return new Date(now);
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { user, card, startDate, endDate } = this.state;
    this.setState({ loading: true });
    this.props.clearFn();
    await this.props.getPayments(
      user ? user.id : null,
      card ? card.id : null,
      startDate,
      endDate
    );
    this.setState({ loading: false });
  };

  onExport = async (e) => {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    const { payments } = this.props;
    if (payments.length !== 0) {
      this.setState({ loadingEx: true });
      let start =
        startDate.getFullYear() +
        '-' +
        (startDate.getMonth() + 1) +
        '-' +
        startDate.getDate();
      let end =
        endDate.getFullYear() +
        '-' +
        (endDate.getMonth() + 1) +
        '-' +
        endDate.getDate();

      let fileName = 'Payments_' + start + '_' + end;

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';

      const ws = XLSX.utils.json_to_sheet(payments);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      this.setState({ loadingEx: false });
    }
  };

  render() {
    const { payments, cards_simple, users_simple, classes } = this.props;
    const {
      user,
      card,
      startDate,
      endDate,
      period,
      loading,
      loadingEx
    } = this.state;

    let cards =
      user !== null
        ? cards_simple.filter((card) => card.user_id === user.id)
        : cards_simple;

    let users =
      card !== null
        ? users_simple.filter((user) => user.id === card.user_id)
        : users_simple;

    const rows = payments.map((row) => ({
      id: row.id,
      id_client: row.id_client,
      email: row.email,
      money: row.money,
      date: row.date,
      description: row.description,
      card: row.card_nr
    }));

    const headCells = [
      { id: 'email', numeric: false, disablePadding: true, label: 'User' },
      { id: 'date', numeric: false, disablePadding: true, label: 'Date' },
      { id: 'card_nr', numeric: false, disablePadding: true, label: 'Card' },
      {
        id: 'description',
        numeric: false,
        disablePadding: true,
        label: 'Description'
      },
      { id: 'money', numeric: false, disablePadding: true, label: 'Money' }
    ];
    return (
      <div className={classes.root}>
        <Typography
          variant="h4"
          style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          Payments
        </Typography>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                Payments History / {user !== null ? user.email : 'All'}
              </Typography>
            </CardContent>
            <Grid container justify="space-around">
              <Autocomplete
                disabled={loading || loadingEx ? true : false}
                id="users"
                onChange={(event, newValue) => {
                  this.setState({ user: newValue });
                }}
                options={users}
                value={user}
                getOptionSelected={(option, user) =>
                  option.email === user.email
                }
                getOptionLabel={(option) => option.email}
                style={{ width: 250, paddingTop: '15px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose User"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                disabled={loading || loadingEx ? true : false}
                id="cards"
                onChange={(event, newValue) => {
                  this.setState({ card: newValue });
                }}
                options={cards}
                value={card}
                getOptionSelected={(option, card) =>
                  option.card_nr === card.card_nr
                }
                getOptionLabel={(option) => option.card_nr}
                style={{ width: 200, paddingTop: '15px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Card"
                    variant="outlined"
                  />
                )}
              />

              <FormControl
                variant="outlined"
                style={{ paddingTop: '15px', width: '200px' }}>
                <InputLabel id="period" style={{ paddingTop: '17px' }}>
                  Period
                </InputLabel>
                <Select
                  disabled={loading || loadingEx ? true : false}
                  labelId="period"
                  id="period"
                  label="Period"
                  value={period}
                  onChange={this.handleChangePeriod}>
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>Yesterday</MenuItem>
                  <MenuItem value={3}>This Week</MenuItem>
                  <MenuItem value={4}>Last Week</MenuItem>
                  <MenuItem value={5}>This Month</MenuItem>
                  <MenuItem value={6}>Last Month</MenuItem>
                  <MenuItem value={7}>Custom</MenuItem>
                </Select>
              </FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disabled={
                    (period === 7 && !loading) || (period === 7 && !loadingEx)
                      ? false
                      : true
                  }
                  inputVariant="outlined"
                  style={{ width: 200 }}
                  autoOk
                  value={startDate}
                  onChange={this.handleStartDateChange}
                  variant="inline"
                  format="yyyy-MM-dd HH:mm"
                  margin="normal"
                  id="start-date"
                  keyboardbuttonprops={{
                    'aria-label': 'start-date'
                  }}
                  label={'Start Date'}
                />
                <DatePicker
                  disabled={
                    (period === 7 && !loading) || (period === 7 && !loadingEx)
                      ? false
                      : true
                  }
                  inputVariant="outlined"
                  style={{ width: 200 }}
                  autoOk
                  value={endDate}
                  onChange={this.handleEndDateChange}
                  variant="inline"
                  format="yyyy-MM-dd HH:mm"
                  margin="normal"
                  id="end-date"
                  keyboardbuttonprops={{
                    'aria-label': 'end-date'
                  }}
                  label={'End Date'}
                />
              </MuiPickersUtilsProvider>
              <Button
                disabled={loading || loadingEx ? true : false}
                style={{ margin: '14px' }}
                color="primary"
                variant="contained"
                onClick={this.onSubmit}>
                Submit
              </Button>
              <Button
                disabled={
                  loading || loadingEx || payments.length === 0 ? true : false
                }
                style={{ margin: '14px' }}
                color="secondary"
                variant="contained"
                onClick={this.onExport}>
                Export
              </Button>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={12} xl={12} md={12} xs={12}>
          <Card>
            <PaymentTable headCells={headCells} rows={rows} loading={loading} />
          </Card>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cards_simple: state.admin_reducer.cards_simple,
  users_simple: state.admin_reducer.users_simple,
  payments: state.admin_reducer.payments
});

export default withStyles(useStyles)(
  connect(mapStateToProps, {
    getSimpleCards,
    getSimpleUsers,
    getPayments,
    loadingFn,
    clearFn
  })(Payments)
);
