import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import { connect } from 'react-redux';
import { loadingFn, clearFn } from '../../actions/appActions';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

class PaymentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleSave = async () => {
    const { amount_pr } = this.props;
    this.setState({
      loading: true
    });
    this.props.clearFn();
    this.props.loadingFn(true);

    //paypal return url sent to api read amout, marchant id if match success, else cancel

    let form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://www.paypal.com/cgi-bin/webscr';

    const bn = '_PP-BuyNowBF';
    const business = 'christo@easyvoipcall.com';
    const item_name = '360Roaming - Top Up';
    const amount = amount_pr.toFixed(2);
    const no_note = '1';
    const rm = '1';
    const charset = 'utf-8';
    const cancel_return = 'https://myaccount.360roaming.com/payments/cancel';
    const currency_code = 'USD';
    const lc = 'CA';
    const custom = localStorage.getItem('jwtTokenid');
    const paymentaction = 'sale';

    const content = [
      {
        Name: 'cmd',
        Value: '_xclick'
      },
      {
        Name: 'bn',
        Value: bn
      },
      {
        Name: 'business',
        Value: business
      },
      {
        Name: 'item_name',
        Value: item_name
      },
      {
        Name: 'amount',
        Value: amount
      },
      {
        Name: 'no_note',
        Value: no_note
      },
      {
        Name: 'rm',
        Value: rm
      },
      {
        Name: 'charset',
        Value: charset
      },
      {
        Name: 'form-charset',
        Value: 'utf-8'
      },
      {
        Name: 'return',
        Value: 'https://myaccount.360roaming.com/payments/success'
      },
      {
        Name: 'notify_url',
        Value: 'https://sip1.easyvoipcall.com/pbxres/api/360paypal.notify'
      },
      {
        Name: 'cancel_return',
        Value: cancel_return
      },
      {
        Name: 'currency_code',
        Value: currency_code
      },
      {
        Name: 'lc',
        Value: lc
      },
      {
        Name: 'custom',
        Value: custom
      },
      {
        Name: 'paymentaction',
        Value: paymentaction
      }
    ];

    for (let i in content) {
      let f = content[i];
      var el = document.createElement('input');
      el.value = f.Value;
      el.name = f.Name;
      form.appendChild(el);
    }

    document.body.appendChild(form);
    form.submit();

    this.setState({
      loading: false
    });
    this.props.handleClose();
  };

  render() {
    const { handleClose, open, amount_pr } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            style={{ backgroundColor: '#3f51b5' }}>
            <b style={{ color: 'white' }}>Confirmation</b>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <b>You will top up your account with Paypal.</b>
            </Typography>
            <Typography gutterBottom>Please confirm or cancel.</Typography>
            <img
              src="/images/satisfaction-guarantee.png"
              alt="Paypal secure"
              height="300px"
            />
            <Alert severity="success">
              {' '}
              <Typography variant="h5" style={{ color: 'white' }}>
                Amount: <b>{amount_pr} USD</b>{' '}
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              disabled={loading ? true : false}>
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={this.handleSave}
              color="secondary"
              disabled={loading ? true : false}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error_axios: state.app_reducer.error_axios
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { loadingFn, clearFn })(PaymentDialog)
);
