export const AUTH_APP = 'AUTH_APP';
export const ERROR_AXIOS = 'ERROR_AXIOS';
export const AUTH_APP_LOGOUT = 'AUTH_APP_LOGOUT';
export const APP_LOADING = 'APP_LOADING';
export const SM_LOADING = 'SM_LOADING';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
export const OD_LOADING = 'OD_LOADING';
export const SIGNUP_APP = 'SIGNUP_APP';
export const CHECK_SIGNUP_TOKEN = 'CHECK_SIGNUP_TOKEN';
export const REGISTER_CARD = 'REGISTER_CARD';
export const FORGOT_PASS = 'FORGOT_PASS';
export const RESET_PASS = 'RESET_PASS';

//Client
export const GET_CARDS = 'GET_CARDS';
export const GET_SIMPLE_CARDS = 'GET_SIMPLE_CARDS';
export const GET_CDRS = 'GET_CDRS';
export const SET_VOICEMAIL = 'SET_VOICEMAIL';
export const GET_BALANCE = 'GET_BALANCE';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const SET_TOPUP = 'SET_TOPUP';
export const SET_AUTOCHARGE = 'SET_AUTOCHARGE';
export const GET_AUTOCHARGE_USER = 'GET_AUTOCHARGE_USER';
export const CANCEL_AUTOCHARGE_USER = 'CANCEL_AUTOCHARGE_USER';
export const SAVE_AUTOCHARGE_USER = 'SAVE_AUTOCHARGE_USER';
export const GET_DID_COUNTRIES = 'GET_DID_COUNTRIES';
export const GET_AREAS = 'GET_AREAS';
export const BUY_DID = 'BUY_DID';
export const GET_DIDS = 'GET_DIDS';
export const SET_CLI = 'SET_CLI';
export const SET_AUTORENEW_DID = 'SET_AUTORENEW_DID';
export const SET_AUTORENEWCHARGE_DID = 'SET_AUTORENEWCHARGE_DID';
export const CANCEL_DID = 'CANCEL_DID';

//Admin
export const GET_CARDS_ADMIN = 'GET_CARDS_ADMIN';
export const ERROR_AXIOS_ADMIN = 'ERROR_AXIOS_ADMIN';
export const APP_LOADING_ADMIN = 'APP_LOADING_ADMIN';
export const SM_LOADING_ADMIN = 'SM_LOADING_ADMIN';
export const OD_LOADING_ADMIN = 'OD_LOADING_ADMIN';
export const CLEAR_RESPONSE_ADMIN = 'CLEAR_RESPONSE_ADMIN';
export const GET_USERS_ADMIN = 'GET_USERS_ADMIN';
export const GET_PAYMENTS_ADMIN = 'GET_PAYMENTS_ADMIN';
export const GET_SIMPLE_CARDS_ADMIN = 'GET_SIMPLE_CARDS_ADMIN';
export const GET_SIMPLE_USERS_ADMIN = 'GET_SIMPLE_USERS_ADMIN';
