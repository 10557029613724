import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import CardsTable from './CardsTable';
import { getCards, loadingFn, clearFn } from '../../actions/appActions';

const useStyles = (theme) => ({
  root: {
    paddingTop: '32px',
    paddingRight: '2px',
    paddingLeft: '21px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2px'
    }
  }
});

class Cards extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  async handleRefresh(offset, limit) {
    this.props.loadingFn(true);
    await this.props.getCards(offset, limit);
  }

  handleClose = async () => {
    this.setState({ open: false });
    this.props.clearFn();
    this.props.loadingFn(true);
    await this.props.getCards(0, 5);
  };


  async UNSAFE_componentWillMount() {
    this.props.clearFn();
    this.props.loadingFn(true);
    await this.props.getCards(0, 5);
  }

  render() {
    const { cards, total_cards, balance, classes } = this.props;
    const { open } = this.state;
    const rows = cards.map((row) => ({
      id: row.id,
      card_nr: row.card_nr,
      order_id: row.order_id,
      status: row.status,
      balance: row.balance,
      auto_charge: row.value,
      next_date: row.next_date,
      currency: row.currency
    }));


    const CustomRouterLink = forwardRef((props, ref) => (
      <div ref={ref} style={{ flexGrow: 1, display: 'contents' }}
      >
        <RouterLink {...props} />
      </div>
    ));

    const headCells = [
      {
        id: 'card_nr',
        numeric: false,
        disablePadding: true,
        label: 'Sim card number'
      },
      { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
      {
        id: 'currency',
        numeric: false,
        disablePadding: true,
        label: 'Currency'
      },
      { id: 'balance', numeric: false, disablePadding: true, label: 'Balance' },
      {
        id: 'value',
        numeric: false,
        disablePadding: true,
        label: 'Auto Charge/month'
      },
      {
        id: 'next_date',
        numeric: false,
        disablePadding: true,
        label: 'Next Charge'
      },
      { id: 'actions', numeric: false, disablePadding: true, label: 'Actions' }
    ];
    return (
      <div className={classes.root}>
        <Typography
          variant="h4"
          style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          SIM Cards
        </Typography>
        <Button
          onClick={() => this.setState({ open: true })}
          style={{ margin: '14px' }}
          color="secondary"
          variant="contained">
          Register Card
        </Button>
        <Button
          style={{ margin: '14px' }}
          color="primary"
          variant="contained"
          component={CustomRouterLink}
          to="/dids">
          Buy Local Number
        </Button>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} xl={8} xs={12}>
            <CardsTable
              headCells={headCells}
              rows={rows}
              handleRefresh={this.handleRefresh}
              total_cards={total_cards}
              balance={balance}
              openRegister={open}
              handleCloseRegister={this.handleClose}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cards: state.app_reducer.cards,
  total_cards: state.app_reducer.total_cards,
  balance: state.app_reducer.balance
});

export default withStyles(useStyles)(
  connect(mapStateToProps, {  getCards, loadingFn, clearFn })(Cards)
);
