import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import UsersTable from './UsersTable';
import { getUsers, loadingFn, clearFn } from '../../actions/adminActions';

const useStyles = (theme) => ({
  root: {
    paddingTop: '32px',
    paddingRight: '2px',
    paddingLeft: '21px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2px'
    }
  }
});

class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  async handleRefresh() {
    this.props.loadingFn(true);
    await this.props.getUsers();
  }

  async UNSAFE_componentWillMount() {
    this.props.clearFn();
    this.props.loadingFn(true);
    await this.props.getUsers();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { users, classes } = this.props;
    const rows = users.map((row) => ({
      id: row.id,
      balance: row.balance,
      auto_payment: row.auto_payment,
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      confirmed: row.confirmed,
      email_confirmation: row.email_confirmation
    }));

    const headCells = [
      {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: 'login'
      },
      {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First Name'
      },
      {
        id: 'last_name',
        numeric: false,
        disablePadding: true,
        label: 'Last Name'
      },
      { id: 'balance', numeric: false, disablePadding: true, label: 'Balance' },
      {
        id: 'confirmed',
        numeric: false,
        disablePadding: true,
        label: 'Confirmed'
      },
      {
        id: 'email_confirmation',
        numeric: false,
        disablePadding: true,
        label: 'Email conf.'
      },
      {
        id: 'auto_payment',
        numeric: false,
        disablePadding: true,
        label: 'Auto payment'
      },
      { id: 'actions', numeric: false, disablePadding: true, label: 'Actions' }
    ];
    return (
      <div className={classes.root}>
        <Typography
          variant="h4"
          style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          Users
        </Typography>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <UsersTable
              headCells={headCells}
              rows={rows}
              handleRefresh={this.handleRefresh}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin_reducer.users
});

export default withStyles(useStyles)(
  connect(mapStateToProps, { getUsers, loadingFn, clearFn })(Users)
);
