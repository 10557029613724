import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Password } from './components';

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(4)
  }
});

class Settings extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Password />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(useStyles)(Settings);
